import React, { useEffect, useState } from "react";
import "./Rules.scss";
import { useStoreState } from "easy-peasy";

import SectionTitle from "../../components/sectionTitle/SectionTitle";
import TermsPopUp from "../../components/termsPopUp/TermsPopUp";

import checkedIcon from '../../assets/icons/checked-icon.svg';

const Rules = () => {
    const { lang } = useStoreState( (store) => store.user );
    const { en, de } = useStoreState( (store) => store.translate );

    const [ termsAndConditions, setTermsAndConditions ] = useState();

    const [ openTermsPopUp, setOpenTermsPopUp ] = useState( { id: null, isOpened: false } );
    const handleOpenPopUp = ( id ) => {
        setOpenTermsPopUp( { id, isOpened: true } )
    }


    return (
        <div className={openTermsPopUp.isOpened ? 'rules popup-open' : 'rules'}>
            <SectionTitle title={lang == 'de' ? de.rules.what_you_need_to_do : en.rules.what_you_need_to_do} />
            {
                lang == 'de' ?
                de.rules.info?.data.map((item, i) => 
                <div key={i} className="terms-list">
                    <span className="step">
                        {
                            `${lang == 'de' ? de.rules.step : en.rules.step} ${i+1}`
                        }
                    </span>
                    <p className="text">{item.text}</p>
                    {
                        item.child ? 
                        <ol type="a" className="child-list">
                            {
                                item.child.map( (el, index) => 
                                    <li key={index}>
                                        <span className="name">
                                            {
                                                el.name
                                            }
                                        </span>
                                        <span className="child-text">
                                            {
                                                el.text
                                            }
                                        </span>
                                    </li>
                                )
                            }
                        </ol> : null
                    }
                </div>
                ) :
                en.rules.info.data.map((item, i) => 
                    <div key={i} className="terms-list">
                        <span className="step">
                            {
                                `${lang == 'de' ? de.rules.step : en.rules.step} ${i+1}`
                            }
                        </span>
                        <p className="text">{item.text}</p>
                        {
                            item.child ? 
                            <ol type="a" className="child-list">
                                {
                                    item.child.map( (el, index) => 
                                        <li key={index}>
                                            <span className="name">
                                                {
                                                    el.name
                                                }
                                            </span>
                                            <span className="child-text">
                                                {
                                                    el.text
                                                }
                                            </span>
                                        </li>
                                    )
                                }
                            </ol> : null
                        }
                    </div>
                ) 
            }
            {
                lang == 'de' ?
                <div className="terms-and-conditions">
                    {
                        de.termsAndConditions.map((item, i) => 
                            <div key={i} className="terms-and-conditions">
                                <button className='btn' onClick={() =>{handleOpenPopUp(i)}} >
                                    <span className="text">{item.typeRules}</span>
                                </button>
                                {
                                    (openTermsPopUp.isOpened && openTermsPopUp.id === i) ? 
                                    <TermsPopUp  
                                        openTermsPopUp={openTermsPopUp}
                                        setOpenTermsPopUp={setOpenTermsPopUp} 
                                        title={item.title}
                                        type={item.type}
                                        data={item.data}
                                        accepted={true}
                                    />:null
                                }
                            </div>
                        )
                    }
                </div> : 
                <div className="terms-and-conditions">
                    {
                        en.termsAndConditions.map((item, i) => 
                            <div key={i} className="terms-and-conditions">
                                <button className='btn' onClick={() =>{handleOpenPopUp(i)}} >
                                    <span className="text">{item.typeRules}</span>
                                </button>
                                {
                                    (openTermsPopUp.isOpened && openTermsPopUp.id === i) ? 
                                    <TermsPopUp  
                                        openTermsPopUp={openTermsPopUp}
                                        setOpenTermsPopUp={setOpenTermsPopUp} 
                                        title={item.title}
                                        type={item.type}
                                        data={item.data}
                                        accepted={true}
                                    />:null
                                }
                            </div>
                        )
                    }
                </div>
            }
        </div>
    );
};

export default Rules;