import { useStoreActions, useStoreState } from "easy-peasy";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Select from "../select/Select";
import VotePopUp from "../votePopUp/VotePopUp";
import './BestPlayer.scss';
import api from "../../api/axios";

const BestPlayer = () => {
    const { lang } = useStoreState( (store) => store.user );
    const { en, de } = useStoreState( (store) => store.translate );

    const { previousBest, selectDayOptions } = useStoreState(
      (store) => store.votePlayer
    );
    const { getPreviousBest, getSelectData } = useStoreActions(
      (actions) => actions.votePlayer
    );
    const { access_token } = useStoreState(
      (store) => store.user
    );
    
    const [refresh, setRefresh] = useState(false);
    const [periodFilter, setPeriodFilter] = useState();
    const [ openVotePopUp, setOpenVotePopUp ] = useState( false );
    const handleOpenPopUp = () => {
        if(previousBest?.isOpenVoting){
            setOpenVotePopUp( true );
        }
    }

    useEffect(() => {
        // setPeriodFilter(selectDayOptions[selectDayOptions.length - 1].value);
        const fetchData = async () => {
            api.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
            await getSelectData();
            // await getPreviousBest(); 
        };
        if (access_token) fetchData().catch(console.error);
    }, [])

    useEffect(() => {
        let date = {
            day: null,
            month: null,
            year: null
        }
        selectDayOptions.map((item) => {
            if(item.value == periodFilter){
                date.day = item.day;
                date.month = item.month;
                date.year = item.year;
            }
        }) 
        getPreviousBest({
            day: date.day,
            month: date.month,
            year: date.year
        });
        setRefresh(false);
    }, [periodFilter, refresh]);

    return (
        <div className="best-player-card">
                <div className="view-winner">
                    <h2 className="card-title">
                        { lang == 'de' ? de.votes.best_player_of_previous_matchday : en.votes.best_player_of_previous_matchday }
                    </h2>
                    {
                        selectDayOptions.length > 1 ?
                        <Select options={selectDayOptions} setValue={setPeriodFilter} last={true}/> : null
                    }
                    {
                        !!previousBest?.playerDetails ? 
                        <div className="result">
                            <span className="icon">
                                <img src={previousBest?.playerDetails?.emblemUrl} alt={previousBest?.playerDetails?.playerName}/>
                            </span>
                            <span className="player">
                                {
                                    previousBest?.playerDetails?.playerName
                                }
                            </span>
                            <span className="votes">
                                <span className="value">{((previousBest?.votes / previousBest?.totalVotes) * 100).toFixed(0)}</span>
                                %
                            </span>
                        </div> : null
                    }
                        
                    <button className="btn-vote" onClick={handleOpenPopUp} >
                        {
                            previousBest?.isOpenVoting ?
                                lang == 'de' ? de.votes.vote_player_of_the_day : en.votes.vote_player_of_the_day
                                :
                                lang == 'de' ? 'MVP-Voting ab 11 Uhr geöffnet' : 'MVP VOTING AVAILABLE FROM 11:00'
                        }
                    </button>
                </div>
                {
                    openVotePopUp ?
                    <VotePopUp  
                        setOpenVotePopUp={setOpenVotePopUp} 
                        hasVoted={previousBest.hasVoted}
                        setRefresh={setRefresh}
                    />:null
                }
        </div>
    );
};
export default BestPlayer;