import React from "react";
import { Outlet } from 'react-router-dom';

import Navigation from "./navigation/Navigation";

import '../styles/base.scss';

import banner from '../assets/banner-wc.png'
import { useStoreState } from "easy-peasy";


const Layout = () => {
    const { lang } = useStoreState( (store) => store.user );
    const { en, de } = useStoreState( (store) => store.translate );
    return (
        <main className="main-container">
            {/* <h1 className="app-title">{ lang == 'de' ? de.general.championship_name : en.general.championship_name }</h1> */}
            <div className="banner-world-cup">
                <img src={banner} alt="World Cup 2022" />
            </div>
            <Navigation />
            <Outlet />
        </main>
    );
};

export default Layout;