import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";

import Layout from "./components/Layout";
import Loading from "./components/loading/Loading";
import api from "./api/axios";

import Register from "./pages/register/Register";
import Tv from "./pages/tv/Tv";
import Matches from "./pages/matches/Matches";
import Leaderboards from "./pages/leaderboards/Leaderboards";
import Prizes from "./pages/prizes/Prizes";
import Rules from "./pages/rules/Rules";


const App = () => {
  const { access_token, isLoading, newAccountSportWorld } = useStoreState((state) => state.user);
  const { login, getRefreshedToken, logout, setIsLoading, getLang } = useStoreActions((actions) => actions.user);

  useEffect(() => {
    api.setupApiInterceptors(getRefreshedToken, logout);
    setIsLoading(false);
  }, [getRefreshedToken, setIsLoading, logout]);

  // Make sure we have the Bearer token  set up when we refresh or login
  useEffect(() => {
    if (!api.defaults.headers.common["Authorization"] && access_token) {
      api.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
    }
  }, [access_token]);


  // Get params
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const getEmailFromParams =    () => { return params.get("email");     };
  const getNicknameFromParams = () => { return params.get("nickname");  };
  const getLangFromParams =     () => { return params.get("lang");      };

  useEffect(() => {
    if (!!getEmailFromParams()) {
      const fetchData = async () => {
        await login({
          email: getEmailFromParams(),
          nickname: getNicknameFromParams(),
          lang: getLangFromParams(),
        });
      };
      fetchData().catch(console.error);
    } else {
      getLang(getLangFromParams());
    }
  }, []);

  return (
    <div className="App">
      {
        isLoading == true ?
          <Loading />
          : 
          access_token && !newAccountSportWorld ? 
            getMainRoutes()
            :  
            getLoginRoutes() 
      }
    </div>
  );
};
export default App;

const getMainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Matches />} />
      </Route>
      <Route path="/leaderboards" element={<Layout />}>
        <Route path="/leaderboards" element={<Leaderboards />} />
      </Route>
      <Route path="/prizes" element={<Layout />}>
        <Route path="/prizes" element={<Prizes />} />
      </Route>
      <Route path="/rules" element={<Layout />}>
        <Route path="/rules" element={<Rules />} />
      </Route>
    </Routes>
  );
};

const getLoginRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Register />} />
      <Route path="/tv/:id" element={<Tv />} />
    </Routes>
  );
};
