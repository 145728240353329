import React, { useEffect, useRef, useState } from "react";

import './Options.scss';


const Options = ({options, setValue}) => {
    const dropdownMenuRef = useRef(null); 
    
    const [ selectedOption, setSelectedOption ] = useState({ value: options[0].value, label: options[0].label });

    const handleSelectedOption = (value, label) => {
        setSelectedOption({ value: value, label: label });
        setValue(value);
    }


    return ( 
        <ul className="options-container" ref={dropdownMenuRef}>
            {
                options.map(
                    (option, i) => 
                        <li 
                            key={i} 
                            className={selectedOption.value == option.value ? 'selected' : ''}
                            id={option.value} 
                            onClick={() => { handleSelectedOption(option.value, option.label) }}
                        >
                            {option.label}
                        </li>
                )
            }
        </ul>  
    );
};
export default Options;