import React, { useState, useEffect,  useRef} from "react";

import './VotePopUp.scss';

import closeIcon from '../../assets/icons/close-icon.svg';
import { useStoreActions, useStoreState } from "easy-peasy";


const VotePopUp = ({
    setOpenVotePopUp,
    hasVoted,
    setRefresh
}) => {
  const { lang } = useStoreState( (store) => store.user );
  const { en, de } = useStoreState( (store) => store.translate );

  const { teams, players } = useStoreState(
    (store) => store.votePlayer
  );
  const { getTeams, getPlayers, sendVote } = useStoreActions(
    (actions) => actions.votePlayer
  );
  

    const menuRef = useRef( null );
    const [ listening, setListening ] = useState( false );  
    const [ newVote, setNewVote ] = useState(false);
    const [ step, setStep ] = useState(hasVoted == true ? "final" : 'select-country');

    const [ selectedCountry, setSelectedCountry ] = useState();
    const [ selectedPlayer, setSelectedPlayer ] = useState();
  
    const listenForOutsideClicks = (
      listening,
      setListening,
      menuRef
    ) => {
      return () => { 
        [ `click`, `touchstart` ].forEach( () => {
          document.addEventListener( `click`, ( evt ) => {
            if ( !listening ) {
              setListening( true );
              return;
            } else {
              const cur = menuRef?.current;
              const node = evt?.target; 
  
              if ( cur ) {
                if( !cur.contains( node ) ){
                    setOpenVotePopUp( false );
                } 
              }
            }
          });
        });
      };
    } 
    
    useEffect( listenForOutsideClicks( listening, setListening, menuRef ) );


    const selectCountry = (item) => {
      setSelectedCountry({
        name: item.teamDetails.name,
        gameId: item.gameId,
        teamId: item.teamDetails._id,
        logo: item.teamDetails.emblemUrl
      });
      getPlayers({gameId: item.gameId, teamId: item.teamDetails._id});
      setStep('select-player');
    };

    const selectPlayer = (item) => {
      setSelectedPlayer({
        name: item.playerName,
        playerId: item.playerId
      });
    };

    const votePlayer = () => {
      if(selectedCountry && selectedPlayer){
        sendVote({
          playerId: selectedPlayer.playerId,
          gameId: selectedCountry.gameId
        });
        setStep('final');
        setNewVote(true);
      }
    };

    useEffect(() => {
      getTeams();
    },[])


    return(
        <div className="pop-up-vote-container">
            <div className="pop-up-container--inner" ref={ menuRef }>
                <div className="btn-close--container">
                    <button className="btn-close" onClick={() => {setOpenVotePopUp( false );}}>
                        <img src={closeIcon} alt="close" />
                    </button>
                </div>
                
                {
                  <div className={step == 'select-country' ? 'step country visible' : 'step country'}>
                    <h3 className="title">
                      { lang == 'de' ? de.votes.select_country : en.votes.select_country }
                    </h3>
                    <ul className="options">
                      {
                        teams?.map((item, index) => 
                          <li className={item?.teamDetails?.name == selectedCountry?.name ? 'option selected' : 'option'} 
                              key={index} 
                              onClick={() => {selectCountry(item)}}>
                            <span className="icon">
                              <img src={item?.teamDetails.emblemUrl} alt={item?.teamDetails?.name} />
                            </span>
                            <span className="name-container">
                              <span className="name">
                                {
                                  item.teamDetails.name
                                }
                              </span>
                            </span>
                          </li>
                        )
                      }
                    </ul>
                  </div>
                }
                {
                  <div className={ step == 'select-player' ? "step player visible" : 'step player' }>
                    <h3 className="title">
                      { lang == 'de' ? de.votes.select_player : en.votes.select_player }
                    </h3>
                    <ul className="options">
                      {
                        players?.players?.map((item, index) =>
                          <li key={index} 
                              className={item?.playerName == selectedPlayer?.name ? 'option selected' : 'option'}
                              onClick={() => {selectPlayer(item)}}
                          >
                            <span className="icon">
                              <img src={selectedCountry?.logo} alt={selectedCountry?.name} />
                            </span>
                            <span className="name">
                              {
                                item?.playerName
                              }
                            </span>
                          </li>
                        )
                      }  
                    </ul>  
                    <button className="btn-vote"
                            onClick={votePlayer}
                    >
                    { lang == 'de' ? de.votes.vote_player_of_the_day : en.votes.vote_player_of_the_day }
                    </button>
                  </div>
                }
                {
                  <div className={ step == 'final' ? "step final visible" : 'step final' }>
                    <h3 className="message">
                      { lang == 'de' ? de.votes.message : en.votes.message }
                    </h3>
                    <button className="btn-back" onClick={() => {setOpenVotePopUp( false ); if(newVote){setRefresh( true );}}}>
                      { lang == 'de' ? de.votes.back_to_matches : en.votes.back_to_matches }
                    </button>
                  </div>
                }

            </div>
        </div>
    );
};

export default VotePopUp;