import React, { useState, useEffect,  useRef} from "react";

import './PredictionPopUp.scss';

import closeIcon from '../../assets/icons/close-icon.svg';

import timeIcon from '../../assets/icons/time-icon.svg';
import dateIcon from '../../assets/icons/date-icon.svg';
import locationIcon from '../../assets/icons/location-icon.svg';
import plusIcon from '../../assets/icons/prediction-plus-icon.svg';
import minusIcon from '../../assets/icons/prediction-minus-icon.svg';
import { useStoreActions, useStoreState } from "easy-peasy";

const PredictionPopUp = ({
    gameId,
    openPredictionPopUp,
    setOpenPredictionPopUp,
    team_one,
    team_two,
    group,
    game_time,
    game_date,
    stadium,
    userBets
}) => {
    const { lang } = useStoreState( (store) => store.user );
    const { en, de } = useStoreState( (store) => store.translate );
  
    const { chalangeId } = useStoreState((store) => store.game);
    const { getOdds, postUserBets, updateUserBets } = useStoreActions( (actions) => actions.matches );
    const { odds } = useStoreState((store) => store.matches);
    const { getMatches } = useStoreActions( (actions) => actions.matches );

    const menuRef = useRef( null );
    const [ listening, setListening ] = useState( false );  
  
    const listenForOutsideClicks = (
      listening,
      setListening,
      menuRef
    ) => {
      return () => { 
        [ `click`, `touchstart` ].forEach( () => {
          document.addEventListener( `click`, ( evt ) => {
            if ( !listening ) {
              setListening( true );
              return;
            } else {
              const cur = menuRef?.current;
              const node = evt?.target; 
  
              if ( cur ) {
                if( !cur.contains( node ) ){
                    setOpenPredictionPopUp( { ...openPredictionPopUp, isOpened: false } );
                } 
              }
            }
          });
        });
      };
    } 
    
    useEffect( listenForOutsideClicks( listening, setListening, menuRef ) );

      const [days, setDays] = useState(0);
      const [hours, setHours] = useState(0);
      const [minutes, setMinutes] = useState(0); 
      const [deadline, setDeadline] = useState(null);  
      const getTime = () => {
        const time = deadline - Date.now();

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));  
      };

    useEffect(() => {
      setDeadline(Date.parse(game_date + ', ' + game_time));
      const interval = setInterval(() => getTime(), 1000);
      return () => clearInterval(interval);
    });


    useEffect(() => {
      if(gameId && chalangeId){

        
        const fetchData = async () => {
          await getOdds({gameId, chalangeId, team_one, team_two});
        }
        fetchData().catch(console.error);
      }
    }, [chalangeId, gameId]);


    const [optionValue, setOptionValue] = useState({ value: null, id: null});

    
    const [scoreT1, setScoreT1] = useState(0);
    const [scoreT2, setScoreT2] = useState(0);
    const [scoreT1IsSet, setScoreT1IsSet] = useState(false);
    const [scoreT2IsSet, setScoreT2IsSet] = useState(false);
    
    const increaseT1Score = () => {
      if(scoreT1 < 9){
        setScoreT1(scoreT1 + 1); 
        updateWinner(scoreT1 + 1, scoreT2, scoreT1IsSet, scoreT2IsSet); 
      } else{
        setScoreT1IsSet(true);
        updateWinner(scoreT1, scoreT2, true, scoreT2IsSet); 
      }
    }
    const decreaseT1Score = () => {
      if(scoreT1 > 0){
        setScoreT1(scoreT1 - 1); 
        updateWinner(scoreT1 - 1, scoreT2, scoreT1IsSet, scoreT2IsSet); 
      } else{
        setScoreT1IsSet(true);
        updateWinner(scoreT1, scoreT2, true, scoreT2IsSet); 
      }
    }
    const increaseT2Score = () => {
      if(scoreT2 < 9){
        setScoreT2(scoreT2 + 1); 
        updateWinner(scoreT1, scoreT2 + 1, scoreT1IsSet, scoreT2IsSet);
      } else {
        setScoreT2IsSet(true);
        updateWinner(scoreT1, scoreT2, scoreT1IsSet, true);
      }
    }
    const decreaseT2Score = () => {
      if(scoreT2 > 0){
        setScoreT2(scoreT2 - 1);
        updateWinner(scoreT1, scoreT2 - 1, scoreT1IsSet, scoreT2IsSet);
      } else{
        setScoreT2IsSet(true);
        updateWinner(scoreT1, scoreT2, scoreT1IsSet, true);
      }
    }

    const updateWinner = (st1, st2) => {
      if(st1 > st2){
        setOptionValue({value: 'team-one', id: odds.hda.team_one.id});
      }
      if(st1 == st2){
        setOptionValue({value: 'draw', id: odds.hda.draw.id});
      }
      if(st1 < st2){
        setOptionValue({value: 'team-two', id: odds.hda.team_two.id});
      }
    }
    


    const sendPrediction = async() => {

      if(optionValue.id && odds.correctScore[scoreT1][scoreT2].id){
        if(userBets){
          const data = {
            oddOptionsId: [optionValue.id, odds.correctScore[scoreT1][scoreT2].id],
          }
          await updateUserBets({chalangeId, gameId, data});
          
        } else {
          const data = {
            challengeId: chalangeId,
            gameId: gameId,
            oddOptionsId: [optionValue.id, odds.correctScore[scoreT1][scoreT2].id],
            spreadBet: false,
            gameBetTemplateId: odds.betTemplate
          }
          await postUserBets(data);
        }
        await getMatches(chalangeId);
        setOpenPredictionPopUp( { ...openPredictionPopUp, isOpened: false } );
      }

    };

    

    useEffect(() => {
      if(userBets){

        if(userBets[0]?.oddOption?.name == team_one.name) setOptionValue({value: 'team-one', id: odds?.hda?.team_one?.id});
        if(userBets[0]?.oddOption?.name == "Draw") setOptionValue({value: 'draw', id: odds?.hda?.draw?.id});
        if(userBets[0]?.oddOption?.name == team_two.name) setOptionValue({value: 'team-two', id: odds?.hda?.team_two?.id});

        if(userBets[1]?.oddOption?.name?.slice(0, -4) == team_one.name){
          setScoreT1(Number(userBets[1]?.oddOption?.name?.substr(userBets[1]?.oddOption?.name?.length - 3).split("-")[0]));
          setScoreT2(Number(userBets[1]?.oddOption?.name?.substr(userBets[1]?.oddOption?.name?.length - 3).split("-")[1]));
        } else{
          setScoreT1(Number(userBets[1]?.oddOption?.name?.substr(userBets[1]?.oddOption?.name?.length - 3).split("-")[1]));
          setScoreT2(Number(userBets[1]?.oddOption?.name?.substr(userBets[1]?.oddOption?.name?.length - 3).split("-")[0]));
        }
        setScoreT1IsSet(true);
        setScoreT2IsSet(true);
      }
    }, []);

    return(
        <div className="pop-up-prediction-container">
            <div className="pop-up-container--inner" ref={ menuRef }>
                <div className="btn-close--container">
                    <button className="btn-close" onClick={() => {setOpenPredictionPopUp( { ...openPredictionPopUp, isOpened: false } );}}>
                        <img src={closeIcon} alt="close" />
                    </button>
                </div>
                <div className="teams">
                  <div className="team_one">
                    <span className="name">
                      {team_one.name}
                    </span>
                    <span className="flag">
                      <img src={team_one.logo} alt="flag" />
                    </span>
                  </div>
                  <div className="versus">
                    <span>
                      vs.
                    </span>
                  </div>
                  <div className="team_two">
                    <span className="name">
                      {team_two.name}
                    </span>
                    <span className="flag">
                      <img src={team_two.logo} alt="flag" />
                    </span>
                  </div>
                </div>
                {
                  group ?
                  <div className={`group ${group.toLowerCase()}`}>{ lang == 'de' ? de.matches.group : en.matches.group }&nbsp;<span>{group}</span></div> : null
                }
                <span className='divider'><hr /></span>
                <div className="info">
                  <span className="time">
                        <span className='icon'>
                            <img src={timeIcon} alt="time: " />
                        </span>
                        <span className='game-time'>{game_time}</span>
                  </span>
                  <span className="date">
                        <span className='icon'>
                            <img src={dateIcon} alt="date: " />
                        </span>
                        <span className='game-date'>{game_date}</span>
                  </span>
                  {
                    stadium ?
                    <span className="stadium">
                          <span className='icon'>
                            <img className='icon' src={locationIcon} alt="location"/>
                          </span>
                          <span className="game-stadium">{stadium}</span>
                    </span> : null
                  }
                </div>
                <div className="timer">
                  {`${lang == 'de' ? de.matches.starts_in : en.matches.starts_in} ${days} ${lang == 'de' ? de.matches.days : en.matches.days}, ${hours} ${lang == 'de' ? de.matches.hours : en.matches.hours} ${minutes} ${lang == 'de' ? de.matches.minutes : en.matches.minutes}`}
                </div>
                <div className="prediction">
                  <div className="prediction-category">
                    <span className="title">
                      {lang == 'de' ? de.matches.who_will_win : en.matches.who_will_win}?
                    </span>
                    <ul className="winner">
                      <li className={optionValue.value == "team-one" ? 'selected' : ''}>
                        <div  className="button" 
                              onClick={() => {
                                setOptionValue({value: 'team-one', id: odds.hda.team_one.id});
                                setScoreT1(0);
                                setScoreT1IsSet(false);  
                                setScoreT2(0);
                                setScoreT2IsSet(false);  
                              }}>
                          <span className="flag">
                            <img src={team_one.logo} alt="flag" />
                          </span>
                        </div>
                        <span className="bet">
                          {
                            odds ? 
                            <span>{`${odds.hda.team_one.value} ${lang == 'de' ? de.matches.pt : en.matches.pt}`}</span> : null
                          } 
                        </span>
                      </li>
                      <li className={optionValue.value == "draw" ? 'selected' : ''}>
                        <div  className="button"
                              onClick={() => {
                                setOptionValue({value: 'draw', id: odds.hda.draw.id});
                                setScoreT1(0);
                                setScoreT1IsSet(false);  
                                setScoreT2(0);
                                setScoreT2IsSet(false);  
                              }}>
                          <span className="draw">
                            {lang == 'de' ? de.matches.draw : en.matches.draw}
                          </span>
                        </div>
                        <span className="bet">
                          {
                            odds ? 
                            <span>{`${odds.hda.draw.value} ${lang == 'de' ? de.matches.pt : en.matches.pt}`}</span> : null
                          } 
                        </span>
                      </li>
                      <li className={optionValue.value == "team-two" ? 'selected' : ''}>
                        <div  className="button"
                              onClick={() => {
                                setOptionValue({value: 'team-two', id: odds.hda.team_two.id});
                                setScoreT1(0);
                                setScoreT1IsSet(false);  
                                setScoreT2(0);
                                setScoreT2IsSet(false);  
                              }}>
                          <span className="flag">
                            <img src={team_two.logo} alt="flag" />
                          </span>
                        </div>
                        <span className="bet">
                          {
                            odds ? 
                            <span>{`${odds.hda.team_two.value} ${lang == 'de' ? de.matches.pt : en.matches.pt}`}</span> : null
                          } 
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="prediction-category">
                    <span className="title">
                      {lang == 'de' ? de.matches.final_score : en.matches.final_score}
                    </span>
                    <div className="score">
                      <ul className="score">
                        <li>
                          <button className="plus" onClick={increaseT1Score}>
                            <img src={plusIcon} alt="+" />
                          </button>
                          <div className="value">
                            <span>{scoreT1IsSet ? scoreT1 : scoreT1}</span>
                          </div>
                          <button className="minus" onClick={decreaseT1Score}>
                            <img src={minusIcon} alt="-" />
                          </button>
                        </li>
                        <li>
                          <button className="plus" onClick={increaseT2Score}>
                            <img src={plusIcon} alt="+" />
                          </button>
                          <div className="value">
                            <span>{scoreT2IsSet ? scoreT2 : scoreT2}</span>
                          </div>
                          <button className="minus" onClick={decreaseT2Score}>
                            <img src={minusIcon} alt="-" />
                          </button>
                        </li>
                      </ul>
                      <span className="bet">
                        {
                          odds  ?
                          <>
                            {/* <span>{odds?.correctScore[scoreT1][scoreT2]?.value}</span>  */}
                            <span>
                              {
                                odds?.correctScore[scoreT1][scoreT2]?.value == 'No available points' ?
                                  lang == 'de' ? "Keine Punkte verfügbar für dieses Ergebnis" : odds?.correctScore[scoreT1][scoreT2]?.value
                                  :
                                  odds?.correctScore[scoreT1][scoreT2]?.value
                              }
                            </span>
                            {
                              !!Number(odds?.correctScore[scoreT1][scoreT2]?.value) ?
                              <span> {lang == 'de' ? de.matches.pt : en.matches.pt}</span> : null
                            }
                          </>
                          : null
                        }
                      </span>
                    </div>
                  </div>
                </div>

                <button className="send btn" onClick={sendPrediction}>
                  {lang == 'de' ? de.matches.place_prediction : en.matches.place_prediction}
                </button>

            </div>
        </div>
    );
};

export default PredictionPopUp;