import React, { useEffect, useState } from "react";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import Select from "../../components/select/Select";
import Options from "../../components/options/Options";

import "./Leaderboards.scss";
import "../../styles/base.scss";

import firstPlaceIcon from '../../assets/icons/first-place-icon.svg';
import secondThirdPlaceIcon from '../../assets/icons/second-third-place-icon.svg';
 
import { useStoreActions, useStoreState } from "easy-peasy";
import Loader from "../../components/loader/Loader";

import useIsInViewPort from "../../hooks/useIsInViewport";
import { useRef } from "react";

import bbc_en from "../../assets/banner-before-competition-en.png";
import bbc_de from "../../assets/banner-before-competition-de.png";

const Leaderboards = () => {
    const { lang } = useStoreState( (store) => store.user );
    const { en, de } = useStoreState( (store) => store.translate );
    const { leaderboards, selectDayOptions, selectStageOptions, leaderboardsComplete, offset } = useStoreState((store) => store.leaderboards);
    const { chalangeId } = useStoreState((store) => store.game);
    const { getSelectData, getLeaderboardsUsers, getLeaderboardsRegion, resetLeaderboards } = useStoreActions((actions) => actions.leaderboards);

    const selectPeriodOptions = [
        { value: 'all-time', label: lang == 'de' ? de.leaderboards.all_time : en.leaderboards.all_time },
        { value: 'daily', label: lang == 'de' ? de.leaderboards.daily : en.leaderboards.daily }, 
        { value: 'stage', label: lang == 'de' ? de.leaderboards.stage : en.leaderboards.stage }, 
    ]
    const typeOptions = [
        { value: 'overall', label: lang == 'de' ? de.leaderboards.overall : en.leaderboards.overall }, 
        { value: 'region', label: lang == 'de' ? de.leaderboards.region : en.leaderboards.region }, 
    ]
    const [periodFilter, setPeriodFilter] = useState(selectPeriodOptions[0].value);
    const [dayFilter, setDayFilter] = useState();
    const [stageFilter, setStageFilter] = useState();
    const [typeFilter, setTypeFilter] = useState(typeOptions[0].value);

    const loaderRef = useRef(null);
    
    const isInViewport = useIsInViewPort(loaderRef);
    const [ loaderVisible, setLoaderVisible ] = useState(false);
    const limit = 10;

    useEffect(() => {
        getSelectData();
    }, []);

    useEffect(() => {
        resetLeaderboards();
    }, [periodFilter ,dayFilter, stageFilter, typeFilter]);

    useEffect(() => {
        resetLeaderboards();
    }, [periodFilter, dayFilter, stageFilter, typeFilter]);

    useEffect(() => {

        if( typeFilter == 'overall'){
            if  (   (periodFilter == "daily" && dayFilter) || 
                    (periodFilter == "stage" && stageFilter) || 
                    periodFilter == "all-time"
                ) {
                    setLoaderVisible(true);
                } else{
                    setLoaderVisible(false);
                }
        } else{
            setLoaderVisible(false);
        }

            if(typeFilter == 'region'){
                setLoaderVisible(false);
                let type = null;
                let startDate = null;
                let endDate = null;
        
                if(periodFilter == 'all-time'){
                    type = 0;
                    startDate = "2021-11-16T00:00:00.000Z";
                    endDate = "2023-11-16T23:59:59.999Z";
                }
                if(periodFilter == 'daily'){
                    type = 2;
                    if(dayFilter){
                        selectDayOptions.map(item => {
                            if(item.value == dayFilter){
                                startDate = item.startDate;
                                endDate = item.endDate;
                            }
                        });
                    }
                }
                if(periodFilter == 'stage'){
                    if(stageFilter == 'Group Matches'){
                        type = 4;
                    }
                    if(stageFilter == 'Round of 16'){
                        type = 5;
                    }
                    if(stageFilter == 'Quarter-finals'){
                        type = 6;
                    }
                    if(stageFilter == 'Semi-finals'){
                        type = 7;
                    }
                    if(stageFilter == '3rd Place and Final'){
                        type = 8;
                    }
                    selectStageOptions.map(item => {
                        if(item.value == stageFilter){
                            startDate = item.startDate;
                            endDate = item.endDate;
                        }
                    });
                }
                if(dayFilter || stageFilter || type == 0){
                    getLeaderboardsRegion({challengeId: chalangeId, type: type, startDate: startDate, endDate: endDate});
                }
            }
        

    }, [periodFilter, dayFilter, stageFilter, typeFilter]);

    useEffect(() => {
        getSelectData();
        // se sterge pana la lansare
            if(typeFilter == 'overall'){
                if(isInViewport && !leaderboardsComplete){
                    let type = null;
                    let startDate = null;
                    let endDate = null;
            
                    if(periodFilter == 'all-time'){
                        type = 0;
                        startDate = "2021-11-16T00:00:00.000Z";
                        endDate = "2023-11-16T23:59:59.999Z";
                    }
                    if(periodFilter == 'daily'){
                        type = 2;
                        if(dayFilter){
                            selectDayOptions.map(item => {
                                if(item.value == dayFilter){
                                    startDate = item.startDate;
                                    endDate = item.endDate;
                                }
                            });
                        }
                    }
                    if(periodFilter == 'stage'){
                        if(stageFilter == 'Group Matches'){
                            type = 3;
                        }
                        if(stageFilter == 'Round of 16'){
                            type = 4;
                        }
                        if(stageFilter == 'Quarter-finals'){
                            type = 5;
                        }
                        if(stageFilter == 'Semi-finals'){
                            type = 6;
                        }
                        if(stageFilter == '3rd Place and Final'){
                            type = 7;
                        }
                        selectStageOptions.map(item => {
                            if(item.value == stageFilter){
                                startDate = item.startDate;
                                endDate = item.endDate;
                            }
                        });
                    }
                    if(dayFilter || stageFilter || type == 0){
                        getLeaderboardsUsers({challengeId: chalangeId, type: type, startDate: startDate, endDate: endDate, offset: offset, limit: limit, leaderboards: leaderboards});
                    }
                }
            }
        

    }, [ isInViewport, loaderVisible, leaderboardsComplete]);

    useEffect(() => {
        setDayFilter(null);
        setStageFilter(null);
    }, [periodFilter]);



    return (
        <div className="leaderboards">
            <SectionTitle title={lang == 'de' ? 'WÄHLE DEINE RANGLISTE' : 'LEADERBOARDS'} />
            <div className="filter-container">
                <Select options={selectPeriodOptions} setValue={setPeriodFilter} />
                {
                    periodFilter == "daily" && selectDayOptions.length > 0 &&
                    <Select label={lang == 'de' ? de.leaderboards.select : en.leaderboards.select} options={selectDayOptions} setValue={setDayFilter} />
                    
                }
                {
                    periodFilter == "stage" &&
                    <Select label={lang == 'de' ? de.leaderboards.select : en.leaderboards.select} options={selectStageOptions} setValue={setStageFilter} />
                    
                }
                <Options options={typeOptions} setValue={setTypeFilter} />
            </div>
            <ul className="clasament-container">
            {
                leaderboards.map(
                    (item, i) => 
                        <li 
                            key={i}
                            className="clasament-item"
                        >
                            {
                                item.position == 1 ? <img className="icon first-place" src={firstPlaceIcon} alt="1" /> : null
                            }
                            {
                                item.position == 2 || item.position == 3 ? <img className="icon" src={secondThirdPlaceIcon} alt="2|3" /> : null
                            }
                            <span className="position">{item.position == 0 ? '-' : item.position}</span>
                            {
                                item.image ?
                                <span className="user-image">
                                    <img src={item.image} alt={item.name} />
                                </span> : null
                            }
                            <span className="name">{item.name}</span>
                            <span className="points">
                                {item.points.toFixed(2)}
                                <span>{lang == 'de' ? de.matches.pts : en.matches.pts}</span>
                            </span>
                        </li>
                )
            }
            </ul>
                
            <div ref={loaderRef}>
                {/* {
                    loaderVisible && !leaderboardsComplete ?
                    <Loader /> : null
                } */}
            </div>
                    
            {/* provizoriu */}
            {/* <div className="banner-before-competition">
                <img src={lang == 'de' ? bbc_de : bbc_en} alt="Not started yet" />
            </div> */}
            
        </div>
    );
};

export default Leaderboards;