import React, { useState, useEffect,  useRef} from "react";

import './TermsPopUp.scss';

import closeIcon from '../../assets/icons/close-icon.svg';


const TermsPopUp = ({
    openTermsPopUp,
    setOpenTermsPopUp,
    title,
    type,
    data,
    id,
    acceptTerms,
    accepted,
    acceptText
}) => {

    const menuRef = useRef( null );
    const [ listening, setListening ] = useState( false );  
  
    const listenForOutsideClicks = (
      listening,
      setListening,
      menuRef
    ) => {
      return () => { 
        [ `click`, `touchstart` ].forEach( () => {
          document.addEventListener( `click`, ( evt ) => {
            if ( !listening ) {
              setListening( true );
              return;
            } else {
              const cur = menuRef?.current;
              const node = evt?.target; 
  
              if ( cur ) {
                if( !cur.contains( node ) ){
                    setOpenTermsPopUp( { ...openTermsPopUp, isOpened: false } );
                } 
              }
            }
          });
        });
      };
    } 
    
    useEffect( listenForOutsideClicks( listening, setListening, menuRef ) );


    return(
        <div className="pop-up-terms-container">
            <div className="pop-up-container--inner" ref={ menuRef }>
                {
                    accepted &&
                    <div className="btn-close--container">
                        <button className="btn-close" onClick={() => {setOpenTermsPopUp( { ...openTermsPopUp, isOpened: false } );}}>
                            <img src={closeIcon} alt="close" />
                        </button>
                    </div> 
                }
                <div className="data">
                    <h2 className="terms-title">
                        {title}
                    </h2>
                    <h3 className="terms-type">
                        {type}
                    </h3>
                    <ol className="data-container">
                    {
                        data.map((item, i) => 
                            <li key={i}>
                                {item.text}
                                {
                                    item.child ?
                                    <ol>
                                        {
                                            item.child.map((item2, i) =>
                                                <li key={i}>
                                                    {item2.text}
                                                    {
                                                        item2.child ?
                                                        <ol>
                                                            {
                                                                item2.child.map((item3, i) =>
                                                                    <li key={i}>
                                                                        {item3.text}
                                                                    </li>
                                                                )
                                                            }
                                                        </ol> : null
                                                    }
                                                </li>
                                            )
                                        }
                                    </ol> : null
                                }
                            </li>
                        )
                    }
                    </ol>
                </div>
                {
                    !accepted &&
                    <button className='btn'
                        onClick={()=>{
                            acceptTerms(id);
                            setOpenTermsPopUp( { ...openTermsPopUp, isOpened: false } );
                        }}
                    >
                        {acceptText}
                    </button>
                }
            </div>
        </div>
    );
};

export default TermsPopUp;