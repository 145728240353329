import { action } from "easy-peasy";
import { thunk } from "easy-peasy";
import { useState } from "react";

import api from "../../api/axios";
import { MATCHES, ODDS } from "./apiRoutes";
import { urlParamReplacer } from "./apiRoutes";

const matchesModel = {
    matches: [],
    odds: null,

    //ACTIONS
    resetMatches: action((state) => {
        state.matches = [];
    }),
    populateMatches: action((state, payload) => { 
        const date = new Date(payload.scheduledDate);
        const hour = date.getHours();
        const minutes = (date.getMinutes()<10?'0':'') + date.getMinutes();
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();

        let unique = true;
        state.matches.map( element => {
            if(element.id == payload._id) unique = false;
        });

        let userBets;
        payload.userBets.map( item => {
            if(item.gameId == payload._id){
                userBets = item;
            }
        });

        let group = null;
        if(payload.competitionName.split(",")[1]){
            payload.competitionName.split(",")[1].split(' ').map( (item, i) => {
                if(item == 'Group'){
                    group = payload.competitionName.split(",")[1].split(' ')[i + 1];
                }
            });
        }
        
        if(unique)
        state.matches.push(
            {
                id: payload._id,
                status: payload.status,
                odds: null,
                userBets: payload.userBets.length > 0 ? payload.userBets : null,
                team_one: {
                    name: payload.homeTeam.name,
                    logo: payload.homeTeam.emblemUrl,
                    score: payload.homeTeam.externalTeamId == payload?.stats?.ordinaryTime?.paramParticipantId1 ?
                            payload?.stats?.ordinaryTime?.paramFloat1 : payload?.stats?.ordinaryTime?.paramFloat2
                },
                team_two: {
                    name: payload.awayTeam.name,
                    logo: payload.awayTeam.emblemUrl,
                    score: payload.awayTeam.externalTeamId == payload?.stats?.ordinaryTime?.paramParticipantId1 ?
                            payload?.stats?.ordinaryTime?.paramFloat1 : payload?.stats?.ordinaryTime?.paramFloat2
                },
                game_time: `${hour}:${minutes}`,
                game_date: `${day} ${month} ${year}`,
                stadium: payload.stadium,
                // group: payload.group
                group: group
            }
        );
    }),
    setOdds: action((state, payload) => {
        const { data, team_one, team_two } = payload;

        let hdaBets = {};
        let correctScoreBets = Array.from(Array(10), () => new Array(9));

        for( let i = 0; i <= 9; i++){
            for( let j = 0; j <= 9; j++){
                correctScoreBets[i][j] = {
                    value: 'No available points',
                    id: null
                };
            }
        }


        data.odds.map( item => {
            if(item.name == "Home Draw Away, Ordinary Time"){
                let team_one_data = null;
                let draw_data = null;
                let team_two_data = null;
                item.oddOptions.map(item => {
                    if(item.name == team_one.name){
                        team_one_data = {
                            id: item._id,
                            value: item.value.toFixed(2)
                        }
                    }
                    if(item.name == 'Draw'){
                        draw_data = {
                            id: item._id,
                            value: item.value.toFixed(2)
                        }
                    }
                    if(item.name == team_two.name){
                        team_two_data = {
                            id: item._id,
                            value: item.value.toFixed(2)
                        }
                    }
                });
                
                hdaBets = {
                    team_one: {
                        value: team_one_data.value,
                        id: team_one_data.id
                    },
                    draw: {
                        value: draw_data.value,
                        id: draw_data.id
                    },
                    team_two: {
                        value: team_two_data.value,
                        id: team_two_data.id
                    }
                };
                
            }
            if(item.name == "Correct Score, Ordinary Time"){
                const team = item.oddOptions[0].name.split(" ")[0];
                item.oddOptions.map( el => {
                    let data = {
                        // team: el.name.slice(0, -3),
                        team: el.name.split(" ")[0],
                        score: {
                            team_one: Number(el.name.split(" ")[el.name.split(" ").length - 1].split("-")[0]),
                            team_two: Number(el.name.split(" ")[el.name.split(" ").length - 1].split("-")[1]),
                        },
                        value: el.value.toFixed(2)
                    }
                    
                    if(data.score.team_one <= 9 && data.score.team_two <= 9){
                        if(data.team == team){
                            correctScoreBets[data.score.team_one][data.score.team_two].value = data.value;
                            correctScoreBets[data.score.team_one][data.score.team_two].id = el._id;
                        } else{
                            correctScoreBets[data.score.team_two][data.score.team_one].value = data.value;
                            correctScoreBets[data.score.team_two][data.score.team_one].id = el._id;
                        }
                    }
                });
            }
        });

        state.odds = {
            betTemplate: data.betTemplate._id,
            hda: hdaBets,
            correctScore: correctScoreBets
        };

    }),


    //THUNCKS
    getMatches: thunk(async (actions, id) => {
        actions.resetMatches();
            
            try{
                const {data} = await api.get(urlParamReplacer(MATCHES.MATCHES, id)); 
                data.map( match => {
                    actions.populateMatches(match);
                });
                
            } catch (error){
                console.error(error);
            }

    }),
    getPrevMatches: thunk(async (actions, id) => {
        actions.resetMatches();
            try{
                const {data} = await api.get(urlParamReplacer(MATCHES.PREVIOUS_MATCHES, id));

                data.map( match => {
                    actions.populateMatches(match);
                });


            } catch (error){
                console.error(error);
            }

    }),
    getOdds: thunk(async (actions, payload) => {

        const { gameId, chalangeId, team_one, team_two } = payload;

        try{
            const {data} = await api.get(urlParamReplacer(ODDS.ODDS, gameId, chalangeId));
            actions.setOdds({data, team_one, team_two});
        } catch ( error ) {
            console.log(error);
        }
    }),
    postUserBets: thunk(async (actions, payload) => {
        await api.post(ODDS.USER_BETS, payload);
    }),
    updateUserBets: thunk(async (actions, payload) => {
        const {chalangeId, gameId, data} = payload;
        await api.patch(urlParamReplacer(ODDS.USER_BETS_UPDATE, chalangeId, gameId), data);
    }),


};
export default matchesModel;