import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { StoreProvider } from 'easy-peasy';
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StoreProvider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={ <App /> } />
        </Routes>
      </BrowserRouter>
    </StoreProvider>
);
