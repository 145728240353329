import { action } from "easy-peasy";
import { thunk } from "easy-peasy";

import api from "../../api/axios";
import { AUTH } from "./apiRoutes";

const userModel = {
  //DATA
  lang: null,
  isLoading: true,
  access_token: null,
  refresh_token: null,
  email: null,
  nickname: null,
  nicknameError: false,
  land: null,
  points: null,
  rank: null,
  profileImage: null,
  newAccountSportWorld: null,


  // ACTIONS
  setLang: action((state, payload) => {
    state.lang = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setAccessToken: action((state, access_token) => {
    state.access_token = access_token;
  }),
  setRefreshToken: action((state, refresh_token) => {
    state.refresh_token = refresh_token;
  }),
  setRefreshedToken: action((state, newAccessToken) => {
    state.access_token = newAccessToken;
  }),
  setEmail: action((state, email) => {
    state.email = email;
  }),
  setLand: action((state, land) => {
    state.land = land;
  }),
  setPoints: action((state, points) => {
    state.points = points;
  }),
  setRank: action((state, rank) => {
    state.rank = rank;
  }),
  setNickname: action((state, nickname) => {
    state.nickname = nickname;
  }),
  setNicknameError: action((state, val) => {
    state.nicknameError = val;
  }),
  setProfileImage: action((state, image) => {
    state.profileImage = image;
  }),
  setNewAccountSportWorld: action((state, newAccountSportWorld) => {
    state.newAccountSportWorld = newAccountSportWorld;
  }),
  setToken: action((state, tokenPayload) => {
    if (tokenPayload) {
      const { accessToken, refreshToken } = tokenPayload;
      state.access_token = accessToken;
      state.refresh_token = refreshToken;
    } else {
      state.access_token = null;
      state.refresh_token = null;
      state.isLoading = false;
    }
  }),

  //THUNKS
  login: thunk(async (actions, { email, nickname, lang}) => {
    const payload = { email, nickname };
    actions.setLang(lang);
    actions.setIsLoading(true);
    actions.setEmail(email);

    try {
      const { data } = await api.post(AUTH.AUTHENTICATION, payload);
      const { nickname, land, profilePictureSW, newAccountSportWorld} = data;

      actions.setToken(data);
      actions.setNickname(nickname);
      actions.setLand(land);
      actions.setProfileImage(profilePictureSW);
      actions.setNewAccountSportWorld(newAccountSportWorld);

    } catch (error) {
      console.error(error);
    }
    actions.setIsLoading(false);
  }),
  getLang: thunk(async (actions, lang) => {
    actions.setLang(lang);
  }),
  getUserStats: thunk(async (actions, chalangeId) => {
    try{
      const {data} = await api.get(AUTH.POINTS_AND_RANK + "?challengeId=" + chalangeId);

      actions.setPoints(data?.allTimeUserRanking.points.toFixed(2));
      actions.setRank(data?.allTimeUserRanking.position);

    } catch (error) {
      console.error(error);
    }
  }),
  register: thunk(async (actions, { nickname, land, image }) => {
    actions.setIsLoading(true);

      try{
        const {data} = await api.patch(AUTH.REGISTER, {
          "nickname": nickname,
          "land": land,
          "profilePicture": image
        });
        if(data){
          actions.setNickname(nickname);
          actions.setProfileImage(image);
          actions.setLand(land);
          actions.setNicknameError(false);
          actions.setNewAccountSportWorld(false);
        }
      } catch (error){
        actions.setNicknameError(true);
        console.error(error);
      }

    actions.setIsLoading(false);
  }),
  getRefreshedToken: thunk(async (actions, skip, { getState }) => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${
        getState().refresh_token
      }`;
      const { data } = await api.post(AUTH.REFRESH_TOKEN);

      actions.setRefreshedToken(data.accessToken);
      return data.accessToken;
    } catch (error) {
      console.error(error);
    }
  }),
};

export default userModel;
