import React, { useEffect, useState } from "react";
import "./Matches.scss";

import MatchCard from "../../components/matchCard/MatchCard";
import Select from "../../components/select/Select";
import BestPlayer from "../../components/bestPlayer/BestPlayer";
import { useStore, useStoreState, useStoreActions } from "easy-peasy";

import api from "../../api/axios";

import bbc_en from "../../assets/banner-before-competition-en.png";
import bbc_de from "../../assets/banner-before-competition-de.png";

const Matches = () => {
  const { lang, profileImage, nickname, points, rank, access_token } = useStoreState( (store) => store.user );
  const { en, de } = useStoreState( (store) => store.translate );
  const { chalangeId } = useStoreState((store) => store.game);
  const { matches } = useStoreState((store) => store.matches);
  const { previousBest, hasVoted } = useStoreState( (store) => store.votePlayer );
  const { getMatches, getPrevMatches } = useStoreActions( (actions) => actions.matches );
  const { getPreviousBest } = useStoreActions( (actions) => actions.votePlayer );
  const { getUserStats } = useStoreActions((actions) => actions.user);
  const { getChalangeID } = useStoreActions((actions) => actions.game);

  const selectOptions = [
    { value: "all-matches-of-the-day", label: lang == 'de' ? de.matches.all_matches_of_the_day : en.matches.all_matches_of_the_day  },
    { value: "previous-matches", label: lang == 'de' ? de.matches.previous_matches : en.matches.previous_matches  },
  ];
  const [selectFilter, setSelectFilter] = useState(selectOptions[0].value);

  useEffect(() => {
    const fetchData = async () => {
      api.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
      await getChalangeID();
      if (chalangeId) {
        if (selectFilter == "all-matches-of-the-day") {
          await getMatches(chalangeId);
        } else {
          await getPrevMatches(chalangeId);
        }
      }
    };
    if (access_token) fetchData().catch(console.error);
  }, [
    access_token,
    chalangeId,
    selectFilter,
    getChalangeID,
    getMatches,
    getPrevMatches,
  ]);

  useEffect(() => {
    getChalangeID();
  }, []);

  useEffect(() => {
    if (chalangeId) {
      getUserStats(chalangeId);
    }
  }, [chalangeId]);

  return (
    <div className="matches">
      <BestPlayer previousBest={previousBest} hasVoted={hasVoted}/>
      <div className="user-profile">
        <div className="user-profile--data">
          <span className="user-image">
            <img src={profileImage} alt={nickname} />
          </span>
          <span className="nickname">{nickname}</span>
        </div>
        <div className="user-profile--ranking">
          <span className="points">
            {points} <span>{ lang == 'de' ? de.matches.pts : en.matches.pts }</span>
          </span>
          <span className="rank">#{rank == 0 ? "-" : rank}</span>
        </div>
      </div>
      <Select options={selectOptions} setValue={setSelectFilter} />
      <ul className="matches-list">
        {matches.map((matchData, i) => (
          <li key={i} className="match-card">
            <MatchCard {...matchData} />
          </li>
        ))}
      </ul>
          
      {/* PROVIZORIU */}
      {/* <div className="banner-before-competition">
        <img src={lang == 'de' ? bbc_de : bbc_en} alt="Not started yet" />
      </div> */}
      
    </div>
  );
};

export default Matches;
