import { action } from "easy-peasy";
import { thunk } from "easy-peasy";
import { PropagateLoader } from "react-spinners";

import api from "../../api/axios";
import { LEADERBOARDS } from "./apiRoutes";
import { urlParamReplacer } from "./apiRoutes";

const leaderboardsModel = {
    // DATA
    leaderboardsComplete: false,
    leaderboards: [],
    selectDayOptions: [],
    selectStageOptions: [],
    offset: 0,

    // ACTIONS
    setLeaderboard: action((state, payload) => { 
        payload.map(item => {
            state.leaderboards.push({
                id: item._id,
                name: item.user.nickname,
                image: item.user.profilePictureSW,
                points: item.points,
                position: item.position
            });
        });
    }),
    setLeaderboardComplete: action((state, payload) => { 
        state.leaderboardsComplete = payload;
    }),
    setLeaderboardLand: action((state, payload) => { 
        payload.map(item => {
            state.leaderboards.push({
                id: item._id,
                name: item.land,
                image: item.landImage,
                points: item.points,
                position: item.position
            });
        });
    }),
    resetLeaderboard: action((state) => { 
        state.offset = 0;
        state.leaderboardsComplete = false;
        state.leaderboards = [];
    }),
    setDailyFilters: action((state, payload) => {
        payload.map((item, index) => {

            let unique = true;
            state.selectDayOptions.map( el => {
                if(item.name == el.value) unique = false;
            });

            if(unique){
                let date = new Date(item.startDate);
                
                state.selectDayOptions.push(
                    {
                        value: item.name,
                        label: 'MD ' + item.name.split('-')[1], 
                        labelDE: 'SPIELTAG ' + item.name.split('-')[1],
                        date: `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`,
                        day: date.getDate(),
                        month: Number(date.getMonth()),
                        startDate: item.startDate,
                        endDate: item.endDate
                    }
                )
            }
        });
    }),
    setStageFilters: action((state, payload) => {
        payload.map(item => {

            let unique = true;
            state.selectStageOptions.map( el => {
                if(item.name == el.value) unique = false;
            });

            if(unique){
                let date = new Date(item.startDate); 
                state.selectStageOptions.push(
                    {
                        value: item.name,
                        label: item.name,
                        labelDE: item.nameDE,
                        startDate: item.startDate,
                        endDate: item.endDate
                    }
                )
            }
        });
    }),
    increaseOffset: action((state, payload) => {
        state.offset += payload;
    }),


    // THUNKS
    getSelectData: thunk(async (actions) => {
        const {data} = await api.get(LEADERBOARDS.SELECT_OPTIONS);
        actions.setDailyFilters(data?.daily);
        actions.setStageFilters(data?.stages);
    }),
    getLeaderboardsUsers: thunk(async (actions, payload) => {

        let leaderboards;
        
        if(payload.leaderboards.length > 0){
            const {data} = await api.get(
                LEADERBOARDS.LEADERBOARDS_USERS + 
                "?challengeId=" + payload.challengeId +
                "&type=" + payload.type + 
                "&startDate=" + payload.startDate  +
                "&endDate=" + payload.endDate +
                "&offset=" + payload.offset +
                "&limit=" + payload.limit + 
                "&lastRankingPosition=" + payload.leaderboards[payload.leaderboards.length - 1].position +
                "&lastRankingPoints=" + payload.leaderboards[payload.leaderboards.length - 1].points +
                "&lastRankingYeppBonus=0"
            );
            leaderboards = data;
        } else{
            const {data} = await api.get(
                LEADERBOARDS.LEADERBOARDS_USERS + 
                "?challengeId=" + payload.challengeId +
                "&type=" + payload.type + 
                "&startDate=" + payload.startDate  +
                "&endDate=" + payload.endDate +
                "&offset=" + payload.offset +
                "&limit=" + payload.limit
            );
            leaderboards = data;
        }
        
        if(payload.offset == 0){
            actions.resetLeaderboard();
        }
        actions.setLeaderboard(leaderboards);
        actions.increaseOffset(payload.limit);
        if(leaderboards.length < payload.limit){
            actions.setLeaderboardComplete(true);
        }

 
    }),
    getLeaderboardsRegion: thunk(async (actions, payload) => {
        
        const {data} = await api.get(
            LEADERBOARDS.LEADERBOARDS_LANDS_TEAMS + 
            "?challengeId=" + payload.challengeId +
            "&type=" + payload.type + 
            "&startDate=" + payload.startDate  +
            "&endDate=" + payload.endDate 
            );

        actions.resetLeaderboard();
        actions.setLeaderboardLand(data);
        actions.setLeaderboardComplete(true);
    }),
    resetLeaderboards: thunk(async (actions) => {
        actions.resetLeaderboard();
    }),

};
export default leaderboardsModel;