import React from "react";
import { Link, useLocation } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import './Navigation.scss';
  
    //   { lang == 'de' ? de.register : en.register }


const Navigation = () => { 
    const { items } = useStoreState(store => store.navigation);
    const { lang } = useStoreState( (store) => store.user );
    const location = useLocation();

    return (
        <ul className="navigation">
            {
                items.map( (item, i) => 
                    <li key={i} className="navigation-item">
                        <Link
                            to={item.url} 
                            className={ location.pathname === item.url ? 'selected' : '' }
                        >
                            <img className="icon" src={item.logo} alt='icon' />
                            <img className="icon icon-hov" src={item.logoHov} alt='icon' />
                            <span>{ lang == 'de' ? item.title_DE : item.title_EN }</span>
                        </Link>
                    </li>
                )
            }
        </ul>
    );
};
  
export default Navigation;