import React, { useState, useEffect,  useRef} from "react";

import './InfoPopUp.scss';

import closeIcon from '../../assets/icons/close-icon.svg';
import { useStoreState } from "easy-peasy";


const InfoPopUp = ({
    openInfoPopUp,
    setOpenInfoPopUp,
    title,
    data,
}) => {
    const { lang } = useStoreState( (store) => store.user );
    const { en, de } = useStoreState( (store) => store.translate );

    const menuRef = useRef( null );
    const [ listening, setListening ] = useState( false );  
  
    const listenForOutsideClicks = (
      listening,
      setListening,
      menuRef
    ) => {
      return () => { 
        [ `click`, `touchstart` ].forEach( () => {
          document.addEventListener( `click`, ( evt ) => {
            if ( !listening ) {
              setListening( true );
              return;
            } else {
              const cur = menuRef?.current;
              const node = evt?.target; 
  
              if ( cur ) {
                if( !cur.contains( node ) ){
                    setOpenInfoPopUp(false);
                } 
              }
            }
          });
        });
      };
    } 
    
    useEffect( listenForOutsideClicks( listening, setListening, menuRef ) );


    return(
        <div className="pop-up-info-container">
            <div className="pop-up-container--inner" ref={ menuRef }>
                <div className="btn-close--container">
                    <button className="btn-close" onClick={() => {setOpenInfoPopUp(false);}}>
                        <img src={closeIcon} alt="close" />
                    </button>
                </div> 
                <div className="data">
                    <h2 className="terms-title">
                        {title}
                    </h2>
                    {
                        data.map((item, i) => 
                            <div key={i} className="terms-list">
                                <span className="step">
                                    {
                                        `${lang == 'de' ? de.rules.step : en.rules.step} ${i+1}`
                                    }
                                </span>
                                <p className="text">{item.text}</p>
                                {
                                    item.child ? 
                                    <ol type="a" className="child-list">
                                        {
                                            item.child.map( (el, index) => 
                                                <li key={index}>
                                                    <span className="name">
                                                        {
                                                            el.name
                                                        }
                                                    </span>
                                                    <span className="child-text">
                                                        {
                                                            el.text
                                                        }
                                                    </span>
                                                </li>
                                            )
                                        }
                                    </ol> : null
                                }
                            </div>
                        )
                    }
                    {/* <ul className="data-container">
                    {
                        data.map((item, i) => 
                            <li key={i}>
                                {
                                    item
                                }
                            </li>
                        )
                    }
                    </ul> */}
                </div>
            </div>
        </div>
    );
};

export default InfoPopUp;