import React, { useEffect, useState } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";

// components
import Select from "../../components/select/Select";
import TermsPopUp from "../../components/termsPopUp/TermsPopUp";
import InfoPopUp from "../../components/InfoPopUp/InfoPopUp";

import checkedIcon from '../../assets/icons/checked-icon.svg';

import api from "../../api/axios";

// images
import Banner from "../../assets/create-profil-banner.png"
import BannerEn from "../../assets/create-profil-banner-en.png"

import "./Register.scss";

const Register = () => {
    
    const { lang, email, nickname, nicknameError, access_token } = useStoreState( (store) => store.user );
    const { en, de } = useStoreState( (store) => store.translate );
    const { lands } = useStoreState( (store) => store.game );
    const { register } = useStoreActions( (actions) => actions.user );
    const { getData } = useStoreActions( (actions) => actions.game );


    
    const landOptions = [];
    const [landAnswer, setLandAnswer] = useState();
    const [localTerms, setLocalTerms] = useState();

    useEffect(() => {
        lands.map(land => {
            landOptions.push({value: land.name, label: land.name});
        });
        if(lang == 'de'){
            setLocalTerms(de.termsAndConditions);
        }else{
            setLocalTerms(en.termsAndConditions);
        }
    });


    useEffect(() => {
        if(access_token) {
            api.defaults.headers.common["Authorization"] = `Bearer ${access_token}`; 
            getData();
        }
    }, [access_token]);

    const [ userImg, setUserImg ] = useState(null);

    const [termsAccepted, setTermsAccepted] = useState([]);
    const acceptTerms = i => {
        let acceptedTerms = [...termsAccepted];
        acceptedTerms[i] = true;
        setTermsAccepted(acceptedTerms)
    }
    const verifyTerms = () => {
        let verify = true;
        let numberOfAnswers = 0;
        if(termsAccepted.length != localTerms.length) return false;
        termsAccepted.map(answer => {
            if(!answer) return false;
            if(answer) numberOfAnswers++;
        });

        if(verify && numberOfAnswers == localTerms.length) return true;
        return false;
    }
    const [ openTermsPopUp, setOpenTermsPopUp ] = useState( { id: null, isOpened: false } );
    const handleOpenPopUp = ( id ) => {
        setOpenTermsPopUp( { id, isOpened: true } )
    }

    const [ openInfoPopUp, setOpenInfoPopUp ] = useState( false);
    const handleOpenInfoPopUp = () => {
        setOpenInfoPopUp( true )
    }

    useEffect(() => {
        lands.map(land => {
            if(land.name == landAnswer) setUserImg(land.image);
        });
    }, [landAnswer]);

    const [ newNickname, setNewNickname ] = useState( !!nickname ? nickname.search("@") == -1 ? nickname.slice(0, 16) : nickname.split("@")[0].slice(0, 16) : null);

    const setNickname = (evt) => {
        let value = evt.target.value;
        if(
            !('a' <= value[value.length - 1] && value[value.length - 1] <= 'z' ) &&
            !('A' <= value[value.length - 1] && value[value.length - 1] <= 'Z' ) &&
            !('0' <= value[value.length - 1] && value[value.length - 1] <= '9' )
        ){ 
            evt.target.value = value.slice(0, -1);
        }
            setNewNickname(evt.target.value); 
    }

      const handleSubmit = async () => {   
        if ( 
            verifyTerms() &&
            !!newNickname &&
            !!landAnswer  &&
            !!userImg
        )  
            await register(
                {
                    nickname: newNickname.search("@") == -1 ? newNickname.slice(0, 16) : newNickname.split("@")[0].slice(0, 16),
                    land: landAnswer,
                    image: userImg
                }
            ); 
      
      };
    

    return (
        <div className="register-page">
            <div className="banner">
                <img src={ lang == 'de' ? Banner : BannerEn} alt="Create profile"/>
            </div>
            <div className="user-details">
                <h2 className="title">{ lang == 'de' ? de.register.your_detail : en.register.your_detail }</h2>
                <span className="info-email">
                    <span className="label">
                        { lang == 'de' ? de.register.email : en.register.email }
                    </span>
                    <span className="data">
                        {email}
                    </span>
                </span>
                <span className="info-nickname">
                    <span className="label">
                        { lang == 'de' ? de.register.nickname : en.register.nickname }
                    </span>
                    <input  type="text" 
                            id="nickname" 
                            name="nickname" 
                            pattern="[A-Za-z]{3}"
                            maxLength="16"
                            defaultValue={!!nickname ? nickname.search("@") == -1 ? nickname.slice(0, 16) : nickname.split("@")[0].slice(0, 16) : ''} 
                            onChange={(e) => {setNickname(e)}}></input>
                    {
                        nicknameError ?
                        <span className="error">{ lang == 'de' ? de.register.error : en.register.error }</span> : null
                    }
                </span>
            </div>
            <div className="pool">
                <Select label={ lang == 'de' ? de.register.select_your_land : en.register.select_your_land } options={landOptions} setValue={setLandAnswer}/>
                <span className="description">{lang == 'de' ? de.register.select_land_description : en.register.select_land_description}</span>
            </div>
            <div className="terms-and-conditions">
                <h2 className="title">{ lang == 'de' ? de.register.terms_and_conditions : en.register.terms_and_conditions }</h2>
                {
                    lang == 'de' ?
                    <div className="info">
                        <button className={ 'btn'} onClick={handleOpenInfoPopUp} >
                            <span className="text">{de.rules.info.title}</span>
                        </button>
                        {
                            openInfoPopUp ? 
                            <InfoPopUp  
                                openInfoPopUp={openInfoPopUp}
                                setOpenInfoPopUp={setOpenInfoPopUp} 
                                title={de.rules.info.title}
                                data={de.rules.info.data}
                            />:null
                        }
                    </div> :
                    <div className="info">
                        <button className={ 'btn'} onClick={handleOpenInfoPopUp} >
                            <span className="text">{en.rules.info.title}</span>
                        </button>
                        {
                            openInfoPopUp ? 
                            <InfoPopUp  
                                openInfoPopUp={openInfoPopUp}
                                setOpenInfoPopUp={setOpenInfoPopUp} 
                                title={en.rules.info.title}
                                data={en.rules.info.data}
                            />:null
                        }
                    </div>
                }
                {
                    localTerms ? 
                    localTerms.map((item, i) =>
                        <div key={i} className="terms-and-conditions">
                            <button className={ termsAccepted[i] ? 'btn accepted':'btn'} onClick={() =>{handleOpenPopUp(i)}} >
                                <span className='icon'>
                                    <img src={checkedIcon} alt="+" />
                                </span>
                                {/* <span className="text"><span>{item.title}</span> - {item.type}</span> */}
                                <span className="text">{item.type}</span>
                            </button>
                            {
                                (openTermsPopUp.isOpened && openTermsPopUp.id === i) ? 
                                <TermsPopUp  
                                    openTermsPopUp={openTermsPopUp}
                                    setOpenTermsPopUp={setOpenTermsPopUp} 
                                    title={item.title}
                                    type={item.type}
                                    data={item.data}
                                    id={i}
                                    acceptTerms={acceptTerms}
                                    accepted={termsAccepted[i]}
                                    acceptText={ lang == 'de' ? de.register.acceptButton : en.register.acceptButton }
                                />:null
                            }
                        </div>
                    ) : null
                }
            </div>


            <div className="submit-container">
                <button className='btn'
                    onClick={handleSubmit}
                >
                    { lang == 'de' ? de.register.create_profile : en.register.create_profile }
                </button>
            </div>
        </div>
    );
};

export default Register;