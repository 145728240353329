import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'; 
import "./Tv.scss";
import { useStoreActions, useStoreState } from "easy-peasy";

import firstPlaceIcon from '../../assets/icons/first-place-icon.svg';
import secondThirdPlaceIcon from '../../assets/icons/second-third-place-icon.svg';

const Tv = () => {
    const { lang } = useStoreState( (store) => store.user );
    const { en, de } = useStoreState( (store) => store.translate );
    
    const { data } = useStoreState( (store) => store.tv );
    const { getLeaderboardDaily, getLeaderboardAllTime, getLeaderboardPlayers } = useStoreActions( (store) => store.tv );

    const { id } = useParams();

    useEffect(() => {
        if(id == "leaderboard-daily"){
            getLeaderboardDaily();
        }
        if(id == "leaderboard-all-time"){
            getLeaderboardAllTime();
        }
        if(id == "leaderboard-players"){
            getLeaderboardPlayers();
        }
    }, []);
    return (
        <div className="tv">
            <div className="header">
                <h1 className="competition-name">
                    {
                        lang == 'de' ? id == 'leaderboard-players' ? 'MVP-RANKING DES TAGES' : de.general.championship_name : en.general.championship_name
                    }
                </h1>
                <h2 className="leaderboard-name">
                    {
                        lang == 'de' ? 
                            id == 'leaderboard-daily' ?
                                de.tv.leaderboard_daily : 
                            id == 'leaderboard-all-time' ?
                                de.tv.leaderboard_all_time :
                            id == 'leaderboard-players' ?
                                de.tv.players_of_the_matchday :
                            null
                        :
                            id == 'leaderboard-daily' ?
                                en.tv.leaderboard_daily : 
                            id == 'leaderboard-all-time' ?
                                en.tv.leaderboard_all_time :
                            id == 'leaderboard-players' ?
                                en.tv.players_of_the_matchday :
                            null
                    }
                </h2>
            </div>
            <div className="body">
                <ul className="clasament-container">
                    {
                        data.map(
                            (item, i) => 
                                <li 
                                    key={i}
                                    className="clasament-item"
                                >
                                    {
                                        item.position == 1 ? <img className="icon first-place" src={firstPlaceIcon} alt="1" /> : null
                                    }
                                    {
                                        item.position == 2 || item.position == 3 ? <img className="icon" src={secondThirdPlaceIcon} alt="2|3" /> : null
                                    }
                                    <span className="position">{item.position == 0 ? '-' : item.position}</span>
                                    {
                                        item.image ?
                                        <span className="user-image">
                                            <img src={item.image} alt={item.name} />
                                        </span> : null
                                    }
                                    <span className="name">{item.name}</span>
                                    <span className="points">
                                        {item.points}
                                        <span>
                                            {
                                                id == 'leaderboard-players' ? "%" : lang == 'de' ? de.matches.pts : en.matches.pts
                                            }
                                        </span>
                                    </span>
                                </li>
                        )
                    }
                </ul>
            </div>
        </div>
    );
};

export default Tv;