import React, { useEffect, useState } from "react";
import Options from "../../components/options/Options";
import "./Prizes.scss";

import { useStoreActions, useStoreState } from "easy-peasy";


const Prizes = () => {
    const { lang } = useStoreState( (store) => store.user );
    const { en, de } = useStoreState( (store) => store.translate );
 

    const typeOptions = [
        { value: 'grand', label: lang == 'de' ? de.prizes.grand_prize : en.prizes.grand_prize }, 
        { value: 'daily', label: lang == 'de' ? de.prizes.daily : en.prizes.daily }, 
        { value: 'stage', label: lang == 'de' ? de.prizes.after_stages : en.prizes.after_stages },
    ]
    const [typeFilter, setTypeFilter] = useState(typeOptions[0].value);
    const [visiblePrize, setVisiblePrize] = useState(lang == 'de' ? de.prizes.daily_data : en.prizes.daily_data);

    useEffect(() => {
        if(typeFilter == 'daily'){ setVisiblePrize(lang == 'de' ? de.prizes.daily_data : en.prizes.daily_data)};
        if(typeFilter == 'stage'){ setVisiblePrize(lang == 'de' ? de.prizes.after_stages_data : en.prizes.after_stages_data)};
        if(typeFilter == 'grand'){ setVisiblePrize(lang == 'de' ? de.prizes.grand_prize_data : en.prizes.grand_prize_data)};
    }, [typeFilter]);

    return (
        <div className="prizes">
            <Options options={typeOptions} setValue={setTypeFilter} />
            {
                visiblePrize ?
                visiblePrize.map((item, i) => 
                    <div key={i} className="prize-card">
                        <div className="prize-card--images">
                            <img src={item.image} alt="prize image" />
                        </div>
                        <div className="prize-card--info">
                            <span className="title">{item.name}</span>
                            <span className="text">{item.text}</span>
                            <span className="value">{item.value}</span>
                        </div>
                    </div>
                ) : null
            }
            
        </div>
    );
};

export default Prizes;