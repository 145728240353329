// FUNCTIONS
export const urlParamReplacer = (url, ...params) => {
    const anyWordStartingWithColonRegex = /:\w+/;
    params.forEach((param) => {
      url = url.replace(anyWordStartingWithColonRegex, param);
    });
    return url;
  };


// ROUTES
export const AUTH = {
    AUTHENTICATION: '/authentication/signin/sportworld',
    REFRESH_TOKEN: "/authentication/refresh-token",
    FORGOT_PASSWORD: "/authentication/forgot-password",
    PROFILE_PICTURE: "/users/profile-picture",
    REGISTER: "/users/sportworld/register",
    POINTS_AND_RANK: "/challenge-rankings/me",
};

export const GAME = {
    TEAMS_AND_LANDS: '/teams/sportworld/lands-teams',
};

export const MATCHES = {
    CHALLENGES: "/official-challenges/sportworld",
    MATCHES: "/challenges/:id/matches/sportworld",
    // PREVIOUD_MATCHES: "/challenges/:id/matches/sportworld?past=true&offset=0&limit=2",
    PREVIOUS_MATCHES: "/challenges/:id/matches/sportworld?past=true"
}

export const ODDS = {
  ODDS: "/odds/:gameId/:challengeId",
  USER_BETS: "/user-bets",
  USER_BETS_UPDATE: "/user-bets/:challengeId/:gameId",
}

export const LEADERBOARDS = {
  SELECT_OPTIONS: "/challenge-rankings/intervals-sw",
  LEADERBOARDS_USERS: "/challenge-rankings",
  LEADERBOARDS_LANDS_TEAMS: "/challenge-rankings/lands-teams"
}

export const VOTES = {
  DATE: "voting/competition-days",
  PREVIOUS_BEST: "/voting/players/previous-best",
  TEAMS: "/voting/teams",
  PLAYERS: "/voting/players/:teamId/:gameId",
  VOTING: "/voting/add", // Body: "playerId" si "gameId" 
}

export const TV = {
  LEADERBOARD_USERS: "/challenge-rankings/tv",
  LEADERBOARD_PLAYERS: "/voting/players/top-best",
}