import matchIcon from '../../assets/icons/match-icon.svg';
import leaderboardsIcon from '../../assets/icons/leaderboards-icon.svg';
import prizesIcon from '../../assets/icons/prizes-icon.svg';
import rulesIcon from '../../assets/icons/rules-icon.svg';
import matchIconHover from '../../assets/icons/match-icon-hover.svg';
import leaderboardsIconHover from '../../assets/icons/leaderboards-icon-hover.svg';
import prizesIconHover from '../../assets/icons/prizes-icon-hover.svg';
import rulesIconHover from '../../assets/icons/rules-icon-hover.svg';

const navigationModel = {
    items: [
        {
            title_EN: 'Matches',
            title_DE: 'Spiele',
            url: '/',
            logo: matchIcon,
            logoHov: matchIconHover
        },
        {
            title_EN: 'Leaderboards',
            title_DE: 'Ranglisten',
            url: '/leaderboards',
            logo: leaderboardsIcon,
            logoHov: leaderboardsIconHover
        },
        {
            title_EN: 'Prizes',
            title_DE: 'Preise',
            url: '/prizes',
            logo: prizesIcon,
            logoHov: prizesIconHover
        },
        {
            title_EN: 'Rules',
            title_DE: 'Teilnahme',
            url: '/rules',
            logo: rulesIcon,
            logoHov: rulesIconHover
        },
    ]
};

export default navigationModel;