import axios from "axios";
import handleJWTExpired from "./utils";

import { AUTH } from "../store/models/apiRoutes";



const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  responseType: "json",
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
});

api.setupApiInterceptors = (getRefreshedToken ) => {

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const message = error?.response?.data?.message;

      const status = error?.response?.status;
      const { url } = error?.config;
      const errorCode = error?.response?.data?.errorCode;

      if (status === 401 && error.config.url !== AUTH.REFRESH_TOKEN){
        const response = await handleJWTExpired(api, error, getRefreshedToken);
        // resolve the original request/s
        return Promise.resolve(response);
      } else {
        console.log("refresh")
      }
      return Promise.reject(error);
    }
  );
};

export default api;
