import { action } from "easy-peasy";
import { thunk } from "easy-peasy";

import api from "../../api/axios";
import { urlParamReplacer, VOTES, LEADERBOARDS } from "./apiRoutes";

const votePlayerModel = {
    // DATA
    previousBest: null,
    hasVoted: null,
    teams: null,
    players: null,
    selectDayOptions: [],

    // ACTIONS
    setPreviousBest: action((state, previousBest) => {
        state.previousBest = previousBest;
    }),
    setTeams: action((state, teams) => {
        state.teams = teams;
    }),
    setPlayers: action((state, players) => {
        state.players = players;
    }),
    setHasVoted: action((state, vote) => {
        state.previousBest.hasVoted = vote;
    }),
    setDailyFilters: action((state, payload) => {
        let array = [];
        payload.map((item, index) => {
            if(index < payload.length - 1){
                let date = new Date(item.startDate);
                array.push(
                    {
                        value: `MD-${index + 1}`,
                        label: `MD ${index + 1}`,
                        labelDE: `SPIELTAG ${index + 1}`,
                        date: `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`,
                        day: date.getDate(),
                        month: date.getMonth(),
                        year: date.getFullYear()
                    }
                )
            }
        });
        state.selectDayOptions = array;
    }),

    // THUNKS
    getPreviousBest: thunk(async (actions, payload) => {
        const {day, month, year} = payload;
        try{
            if(day && month && year){
                const {data} = await api.get(`${VOTES.PREVIOUS_BEST}?day=${day}&month=${month}&year=${year}`);
                actions.setPreviousBest(data);
                console.log(data);
            } else {
                const {data} = await api.get(VOTES.PREVIOUS_BEST);
                actions.setPreviousBest(data);
                actions.setHasVoted(data.hasVoted);
            }
        } catch ( error ){
            console.log( error );
        }
    }),
    getTeams: thunk(async (actions) => {
        try{
            const {data} = await api.get(VOTES.TEAMS);
            actions.setTeams(data);
        } catch ( error ){
            console.log( error );
        }
    }),
    getPlayers: thunk(async (actions, payload) => {
        const { gameId, teamId } = payload;
        try{
            const {data} = await api.get(urlParamReplacer(VOTES.PLAYERS, teamId, gameId));
            actions.setPlayers(data);
        } catch ( error ){
            console.log( error );
        }
    }),
    getSelectData: thunk(async (actions) => {
        const {data} = await api.get(LEADERBOARDS.SELECT_OPTIONS);
        actions.setDailyFilters(data?.daily);
    }),
    // getSelectData: thunk(async (actions) => {
    //     const {data} = await api.get(VOTES.DATE);
    //     actions.setDailyFilters(data);
    // }),
    sendVote: thunk(async (actions, payload) => {
        await api.post(VOTES.VOTING, payload);
        actions.setHasVoted(true);
    }),

};
export default votePlayerModel;