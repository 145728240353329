import React, {useState} from "react";
import './MatchCard.scss';

import PredictionPopUp from "../predictionPopUp/PredictionPopUp";

import timeIcon from '../../assets/icons/time-icon.svg';
import dateIcon from '../../assets/icons/date-icon.svg';
import locationIcon from '../../assets/icons/location-icon.svg';
import plusIcon from '../../assets/icons/plus-icon.svg';
import changeIcon from '../../assets/icons/change-icon.svg';
import { useStoreState } from "easy-peasy";


const MatchCard = ({id, status, userBets, team_one, team_two, game_time, game_date, stadium, group}) => {

    const { lang } = useStoreState( (store) => store.user );
    const { en, de } = useStoreState( (store) => store.translate );

    const [ openPredictionPopUp, setOpenPredictionPopUp ] = useState( { id: null, isOpened: false } );

    const handleOpenPopUp = ( id ) => {
        setOpenPredictionPopUp( { id, isOpened: true } )
    }
    return (
        <>
        <div className='match-card--container'>
            <div className='team-and-date'>
                <div className='team'>
                    <div className="team_one">
                        <span className='name'>
                            {team_one.name}
                        </span>
                        <span className='flag'>
                            {
                                team_one.logo ? 
                                <img src={team_one.logo} alt={team_one.name} />:null
                            }
                        </span>
                        {
                            status == 'Ended' ?
                            <span className="score">
                                {
                                    team_one.score
                                }
                            </span> : null
                        }
                    </div>
                    <div className="team_two">
                        <span className='name'>
                            {team_two.name}
                        </span>
                        <span className='flag'>
                            {
                                team_two.logo ? 
                                <img src={team_two.logo} alt={team_two.name} />:null
                            } 
                        </span>
                        {
                            status == 'Ended' ?
                            <span className="score">
                                {
                                    team_two.score
                                }
                            </span> : null
                        }
                    </div>
                    {
                        stadium ? 
                        <div className='location'>
                            <img className='icon' src={locationIcon} alt="location"/>
                            <span>{stadium}</span>
                        </div> : null
                    }
                </div>
                <span className='divider'></span>
                <div className='date'>
                    <div className='date-container--inner'>
                        <span className='icon'>
                            <img src={timeIcon} alt="time: " />
                        </span>
                        <span className='game-time'>{game_time}</span>
                        <span className='icon'>
                            <img src={dateIcon} alt="date: " />
                        </span>
                        <span className='game-date'>{game_date}</span>
                        {
                            group ?
                            <>
                                <span className={`group-icon ${group.toLowerCase()}`}></span>
                                <div  className="group-title">Group&nbsp;<span>{group}</span></div>
                            </> : null
                        }
                    </div>
                </div>
            </div>
            <span className='divider'><hr /></span>
            <div className='choices-and-prediction'>
                <div className='choices'>
                    <span className="type">{ lang == 'de' ? de.matches.who_will_win : en.matches.who_will_win }</span>
                    <span className={userBets && userBets[0]?.earnedPoints != 0 ? 'choice win' : 'choice'}>
                        {
                            userBets ?
                                userBets[0]?.oddOption?.name == 'Draw' ?
                                    'X'
                                    :
                                    userBets[0]?.oddOption?.name
                                : 
                                <>-</>
                        }
                    </span>
                    <span className="type">{ lang == 'de' ? de.matches.final_score : en.matches.final_score }</span>
                    <span className={userBets && userBets[1]?.earnedPoints != 0 ? 'choice win' : 'choice'}>
                        {
                            userBets ?
                                userBets[1]?.oddOption?.name?.slice(0, -4) == team_one.name ?
                                    userBets[1]?.oddOption?.name?.substr(userBets[1]?.oddOption?.name?.length - 3) :
                                    userBets[1]?.oddOption?.name?.substr(userBets[1]?.oddOption?.name?.length - 3).split("").reverse().join("")
                                : <>-</>
                        }
                    </span>
                    <span className="points">{ lang == 'de' ? de.matches.pts : en.matches.pts }</span>
                    <span className={userBets && (userBets[0]?.earnedPoints != 0 || userBets[1]?.earnedPoints != 0)  ? 'value win' : 'value'}>
                        {
                            status == 'Pending' || status == 'In Progress'? 
                                userBets?
                                    (userBets[0]?.oddOption?.value + userBets[1]?.oddOption?.value).toFixed(2)
                                    : 
                                    <>-</>
                                :
                                userBets ?
                                    (userBets[0]?.earnedPoints + userBets[1]?.earnedPoints).toFixed(2)
                                    :
                                    <>-</>
                        }
                    </span>
                </div>
                <div className='prediction'>
                    {
                        status == 'Pending' ? 
                        <button className='btn' onClick={() => handleOpenPopUp(id)} >
                            <span className='icon'>
                                {
                                    userBets ? <img src={changeIcon} alt="<->" /> : <img src={plusIcon} alt="+" />
                                }        
                            </span>
                            <span>
                                {
                                    userBets ?  lang == 'de' ? de.matches.change_prediction : en.matches.change_prediction  :  lang == 'de' ? de.matches.place_prediction : en.matches.place_prediction 
                                }
                            </span>
                        </button> : status == 'In Progress' ?
                        <div className="live-game">
                            {
                                !!(team_one.score + '') && !!(team_two.score + '') && team_one.score != undefined && team_two.score != undefined?
                                    <span>{`${team_one.score} - ${team_two.score}`}</span>
                                    :
                                    lang == 'de' ?
                                        de.matches.live
                                        :
                                        en.matches.live
                            } 
                        </div> : null
                        // <div className="live-game">
                        //     <span>{lang == 'de' ? de.matches.live : en.matches.live}</span>
                        // </div> : null
                    }
                </div>
            </div>
        </div>
        {
            (openPredictionPopUp.isOpened && openPredictionPopUp.id === id) ? 
            <PredictionPopUp  
                gameId={id}
                openPredictionPopUp={openPredictionPopUp}
                setOpenPredictionPopUp={setOpenPredictionPopUp} 
                team_one={team_one}
                team_two={team_two}
                group={group}
                game_time={game_time}
                game_date={game_date}
                stadium={stadium}
                userBets={userBets}
            />:null
        }
        </>
    );
};
export default MatchCard;