import { action } from "easy-peasy";
import { thunk } from "easy-peasy";

import api from "../../api/axios";
import { TV } from "./apiRoutes";

const tvModel = {
    // DATA
    data: [],

    // ACTIONS
    setData: action((state, data) => {
        data.topBestVotePlayers.map( (item, index) => {
            state.data.push({
                name: item.playerDetails.playerName,
                position: index + 1,
                image: item.playerDetails.emblemUrl,
                points: (item.votes / data.totalVotes * 100).toFixed(0)

            });
        });
    }),
    setDataPlayers: action((state, data) => {
        data.map( (item, index) => {
            state.data.push({
                name: item.user.nickname,
                position: item.position,
                image: item.user.profilePictureSW,
                points: item.points.toFixed(2),

            });
        });
    }),

    // THUNKS
    getLeaderboardDaily: thunk(async (actions) => {
        try{
            const {data} = await api.get(TV.LEADERBOARD_USERS + "?type=2");
            actions.setDataPlayers(data);
        } catch (error){
            console.error(error);
        }
    }),
    getLeaderboardAllTime: thunk(async (actions) => {
        try{
            const {data} = await api.get(TV.LEADERBOARD_USERS + "?type=0");
            actions.setDataPlayers(data);
        } catch (error){
            console.error(error);
        }
    }),
    getLeaderboardPlayers: thunk(async (actions) => {
        try{
            const {data} = await api.get(TV.LEADERBOARD_PLAYERS);
            actions.setData(data);
        } catch (error){
            console.error(error);
        }
    }),
};
export default tvModel;