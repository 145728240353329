import navigationModel from "./navigationModel";
import userModel from "./userModel";
import matchesModel from "./matchesModel";
import leaderboardsModel from "./leaderboardsModel";
import gameModel from "./gameModel"; 
import translateModel from "./translateModel";
import votePlayerModel from "./votePlayerModel";
import tvModel from "./tvModel";

const model = {
  user: userModel,
  game: gameModel,
  translate: translateModel,
  navigation: navigationModel,
  matches: matchesModel,
  leaderboards: leaderboardsModel, 
  votePlayer: votePlayerModel,
  tv: tvModel,
};

export default model;
