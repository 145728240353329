import dp1_en from '../../assets/prizes/daily/prize_card_1_en.png';
import dp1_de from '../../assets/prizes/daily/prize_card_1_de.png';

import sp1_en from '../../assets/prizes/stages/prize_card_1.png';
import sp2_en from '../../assets/prizes/stages/prize_card_2.png';
import sp3_en from '../../assets/prizes/stages/prize_card_3.png';
import sp4_en from '../../assets/prizes/stages/prize_card_4.png';

import sp1_de from '../../assets/prizes/stages/prize_card_1.png';
import sp2_de from '../../assets/prizes/stages/prize_card_2.png';
import sp3_de from '../../assets/prizes/stages/prize_card_3.png';
import sp4_de from '../../assets/prizes/stages/prize_card_4.png';

import gp1_en from '../../assets/prizes/grand/prize_card_1_en.png';
import gp2_en from '../../assets/prizes/grand/prize_card_2_en.png';
import gp3_en from '../../assets/prizes/grand/prize_card_3_en.png';

import gp1_de from '../../assets/prizes/grand/prize_card_1_de.png';
import gp2_de from '../../assets/prizes/grand/prize_card_2_de.png';
import gp3_de from '../../assets/prizes/grand/prize_card_3_de.png';

const translateModel = {
    en: {
        general: {
            championship_name: "Samsung Tippspiel"
        },
        register: {
            your_detail: "Your details:",
            email: "Email:",
            nickname: "Nickname:",
            select_your_land: "Select your land",
            select_land_description: "Select your state to be shown on the leaderboard",
            select_your_favorite_team: "Select your favorite team",
            terms_and_conditions: "Terms and conditions:",
            acceptButton: "Accept",
            error: "Nickname is already in use.",
            create_profile: "Start to Play",
        },
        tv: {
            leaderboard_daily: "leaderboard daily",
            leaderboard_all_time: "leaderboard all time",
            players_of_the_matchday: "MVP of the previous matchday",
        },
        matches: {
            pts: "pts.",
            all_matches_of_the_day: "All matches of the day",
            previous_matches: "previous matches",
            who_will_win: "Who will win",
            final_score: "Final score",
            place_prediction: "Place prediction",
            change_prediction: "Change prediction",
            live: "Live",
            group: "Group",
            starts_in: "Starts in",
            days: "days",
            hours: "hours and",
            minutes: "minutes",
            draw: "draw",
            pt: "pt."

        },
        votes: {
            best_player_of_previous_matchday: "MVP of previous matchday",
            vote_player_of_the_day: "Vote MVP of the day and win prizes",
            select_country: "Select country:",
            select_player: "Select player:",
            message: "Thank you! Your vote was submitted.",
            back_to_matches: "back to matches",

        },
        leaderboards: {
            daily: "daily",
            stage: "stage",
            all_time: "all time",
            select: "select",
            group_matches: "Group Matches",
            round_of_16: "Round of 16",
            quarter_finals: "Quarter-finals",
            semi_finals: "Semi-finals",
            finals: "3rd Place and Fina",
            overall: "Overall",
            region: "Region",
            favorite_team: "Favorite team",
            
        },
        prizes: {
            daily: "daily",
            after_stages: "After stages",
            grand_prize: "Grand prize",
            daily_data: [
                {
                    image:  dp1_en,
                    name:   '10 x 1 Samsung Sport Paket Voucher for 6 Months',
                    text:   'Top 10 participants on every matchday ',
                    value:  'Value: 20686€',
                },
            ],
            after_stages_data: [
                {
                    image:  sp1_en,
                    name:   '1 x Samsung Galaxy Tab S8 Ultra 5G (128GB)',
                    text:   'Winner of the leaderboard after Group Stages',
                    value:  'Value: 1179€',
                },
                {
                    image:  sp2_en,
                    name:   '1 x Samsung Soundbar HW-Q995B/ZG',
                    text:   'Winner of the leaderboard after Round of 16',
                    value:  'Value: 1499€',
                },
                {
                    image:  sp3_en,
                    name:   '1 x Samsung Galaxy Z Flip4 (128GB)',
                    text:   'Winner of the leaderboard after Quarter Finals',
                    value:  'Value: 1099€',
                },
                {
                    image:  sp4_en,
                    name:   '1 x Samsung TV neo QLED 8K (65")',
                    text:   'Winner of the leaderboard after all 3 finals',
                    value:  'Value: 4199€',
                },
            ],
            grand_prize_data: [
                {
                    image:  gp1_en,
                    name:   '1 x Bundle (Samsung TV 65" Neo QLED + Samsung Galaxy Z Flip4 + Samsung Sport Paket Voucher for 6 Months)',
                    text:   'Winner of the overall leaderboard at the end of the tournament',
                    value:  'Value: 6888€',
                },
                {
                    image:  gp2_en,
                    name:   '1 x Bundle (Samsung TV 65" Neo QLED + Samsung Sport Paket Voucher for 6 Months)',
                    text:   '1 winner; draw among all participants that have voted an Matchday MVP  at least once.',
                    value:  'Value: 4289€',
                },
                {
                    image:  gp3_en,
                    name:   '25 x 1 Samsung Sport Paket Voucher for 6 Months',
                    text:   '25 winners; draw among all participants that have placed a predictions in the competition game at least once',
                    value:  'Value: 2248€',
                },
            ],

        },
        rules: {
            what_you_need_to_do: "What you need to do:",
            step: "Step",
            info: {
                title: 'info',
                data: [
                    {
                        text: "OPEN THE SPORTWORLD APP ON YOUR SAMSUNG DEVICE\n\nYou have a Samsung smartphone or tablet: Open the Sportworld mobile app and go to the 'Samsung betting game' menu item.\nYou have a Samsung Smart TV: Open the Sportworld app on your TV and scan the QR code displayed in the 'Samsung betting game' section with your smartphone."
                    },
                    {
                        text: "REGISTER OR LOG IN\n\nRegister for free at https://sportworld.tv and create your Sportworld account. Follow the instructions on your screen. You already have an account: Then log in under 'Login'."
                    },
                    {
                        text: 'CHOOSE YOUR NICKNAME AND PARTICIPATE IN THE BETTING GAME\n\nIn the Sportworld mobile app, select the menu item "Predictor game", choose a nickname and accept the conditions of participation. Participation in the betting game is of course free of charge.'
                    },
                    {
                        text: 'BET ON EVERY DAY\n\nEvery matchday of the tournament new matches are unlocked. Therefore, visit the Sportworld app every day to place your bets. Bet on who wins as well as on the exact result. You can place or change your bets until the kick-off.'
                    },
                    {
                        text: "POINTS BASED ON REAL PROBABILITIES\n\nWith each correct tip, you earn points calculated based on real market probabilities of occurrence. Compete with the entire Samsung community on our leaderboards.\nThe following rankings are crucial for you:",
                        child: [
                            {
                                name: "All-time:",
                                text: "This ranking is generated continuously and includes all games of the tournament.",
                            },
                            {
                                name: "Daily:",
                                text: "This ranking is generated for each tournament day and includes all matches of that tournament day.",
                            },
                            {
                                name: "Stages:",
                                text: "This ranking is generated for each tournament stage (group stage, round of 16, quarterfinals & finals) and includes all matches of the respective tournament stage.",
                            },
                        ],
                    },
                    {
                        text: 'WIN ATTRACTIVE SAMSUNG PRIZES WORTH UP TO 40,000 EUR\n\nJust by participating, you secure your chance to win attractive prizes. You can find all the details about the prizes and the competition mechanics under the menu item "Samsung Tippspiel" in your Sportworld app.\n\nThe exciting part: you can always win, no matter when you enter. We wish you lots of fun and above all: good luck!'
                    },
                ]
            }
        },
        termsAndConditions: [
            {
                title: 'Sportworld',
                type: 'Accept Terms & Conditions',
                typeRules: 'Terms & Conditions',
                data: [
                    {
                        text: `Name des Gewinnspiels und Geltungsbereich\n
                        Der Name des Gewinnspiels lautet SAMSUNG TIPPSPIEL (im Folgenden als „Gewinnspiel“ bezeichnet). Für die Teilnahme an dem Gewinnspiel "SAMSUNG TIPPSPIEL" und dessen Durchführung gelten diese Teilnahmebedingungen. 
                        `,
                    },
                    {
                        text: `Veranstalter des Gewinnspiels und Gewinnspielzeitraum\n`,
                        child: [
                            {
                                text: `Das Gewinnspiel wird von der B1 SmartTV GmbH, Ainmillerstraße 28, 80801 München, eingetragen im Handelsregister des Amtsgerichts München unter HRB 235921 (im Folgenden als „Veranstalter“ bezeichnet) durchgeführt.`,
                            },
                            {
                                text: `Der Gewinnspielzeitraum am Gewinnspiel erstreckt sich auf die Dauer der Fußball-Weltmeisterschaft 2022 vom 20.11.2022, 00:00:01 Uhr (MEZ) bis zum 18.12.2022, 23:59:59 Uhr (MEZ) (der vorgenannte Zeitraum wird nachfolgend auch als „Gewinnspielzeitraum“ bezeichnet). Innerhalb dieses Gewinnspielzeitraums besteht die Möglichkeit, am Gewinnspiel teilzunehmen und Teilnahmeberechtigte können sich bis zum Ende des Gewinnspielzeitraumes zur Teilnahme am Gewinnspiel anmelden.`,
                            }
                        ],
                    },
                    {
                        text: `Teilnahmeberechtigung und Anmeldung zur Teilnahme\n`,
                        child: [
                            {
                                text: `Teilnahmeberechtigt sind natürliche Personen mit einem eingerichteten Nutzeraccount in der mobilen Anwendung „Sportworld“ auf einem berechtigten Gerät (3.4), die ihren Wohnsitz in Deutschland haben. Bei minderjährigen Teilnehmern ist eine Einverständniserklärung eines Erziehungsberechtigten einzureichen.`
                            },
                            {
                                text: `Die Einhaltung der Art und Weise, wie das Gewinnspiel gemäß den Bestimmungen dieser Teilnahmebedingungen durchgeführt wird, ist für alle Teilnehmer verpflichtend.`
                            },
                            {
                                text: `Die Teilnahme am Gewinnspiel ist unentgeltlich und nicht an den Erwerb von Waren oder Dienstleistungen gebunden.`
                            },
                            {
                                text: `Das Gewinnspiel ist nur in der mobilen Anwendung „Sportworld“ auf Smartphones und Tablets des Herstellers Samsung zugänglich sowie auf Smartphones und Tablets anderer Hersteller, wenn Kunden im Rahmen einer Samsung Werbeaktion ein Samsung-Aktionsgerät erworben und hierfür von Samsung einen QR-Code als Zugangsmöglichkeit zum Gewinnspiel auf mobilen Endgeräten erhalten haben sowie für Kunden mit einem SmartTV des Herstellers Samsung ab Modelljahr 2018, auf denen die „Sportworld“ Anwendung installiert ist und ein QR-Code als Zugangsmöglichkeit zum Gewinnspiel auf mobilen Endgeräten bereitgestellt wird.`
                            },
                            {
                                text: `Die Anmeldung zum Gewinnspiel und die Teilnahme am Gewinnspiel erfolgt ausschließlich in der mobilen Anwendung „Sportworld“. In den vorgesehenen Textfeldern des Anmeldeformulars sind eine E-Mail-Adresse und ein selbst gewählter Nickname (= Teilnehmer) einzugeben. Jeder Nickname kann nur einmal vergeben werden und darf keine Umlaute besitzen und maximal 10 Zeichen lang sein. Eine Verwendung von Nicknamen, die gegen Gesetze verstoßen oder beleidigenden oder anstößigen Inhalts sind, sind nicht zugelassen und der Veranstalter behält sich vor, den dagegen verstoßenden Nicknamen zu ändern oder den Teilnehmer vom Gewinnspiel auszuschließen.`
                            },
                            {
                                text: `Jeder Teilnahmeberechtigte darf nur unter einem Nicknamen am Gewinnspiel teilnehmen und kann sich nur einmal registrieren. Mehrfachanmeldungen eines Teilnehmers bleiben zur Wahrung der Chancengleichheit unberücksichtigt. Wenn sich herausstellt, dass ein Teilnehmer mehrere Accounts registriert hat und/oder falsche Identitäten zur Registrierung nutzt, wird dieser vom Gewinnspiel ausgeschlossen.`
                            },
                            {
                                text: `Alle Mitarbeiter des Veranstalters, des Partners Samsung sowie der vom Partner beauftragten Dienstleisters, die an der Gewinnspielkonzeption und -durchführung beteiligt sind, sowie der mit diesen verbundenen Unternehmen, sind von der Teilnahme am Gewinnspiel ausgeschlossen. Auch deren Lebenspartner und Angehörige in häuslicher Gemeinschaft sind von der Teilnahme ausgeschlossen.`
                            },
                            {
                                text: `Ebenfalls ausgeschlossen sind Gewinnspielagenturen sowie automatisierte Gewinnspieldienste.`
                            },
                            {
                                text: `Der Veranstalter behält sich zudem das Recht vor, Personen von der Teilnahme auszuschließen, sofern berechtigte Gründe vorliegen, wie z.B. Verstoß gegen die Teilnahmebedingungen oder unzulässige Beeinflussung oder Manipulation des Gewinnspiels, doppelte oder mehrfache Teilnahme, und gegen diese Personen rechtliche Schritte einzuleiten. Der Veranstalter kann Teilnehmer in diesen Fällen auch nachträglich vom Gewinnspiel ausschließen, Gewinne aberkennen und diese zurückfordern. `
                            },
                            {
                                text: `Der Veranstalter ist berechtigt, das Gewinnspiel jederzeit ohne Vorankündigung und ohne Angabe von Gründen vorzeitig zu beenden, anzupassen oder abzuändern, wenn eine ordnungsgemäße Durchführung des Gewinnspiels ohne entsprechende Maßnahmen aus technischen (z. B. Viren im Computersystem, Manipulation oder Fehler in der Hard- und/oder Software) oder rechtlichen Gründen nicht gewährleistet werden kann. Der Veranstalter ist dazu verpflichtet, die vorzeitige Beendigung bzw. Anpassung unverzüglich bekannt zu geben.`
                            },
                        ],
                    },
                    {
                        text: `Ablauf und Durchführung des Gewinnspiels (Spielregeln)\n`,
                        child: [
                            {
                                text: `Das Gewinnspiel ist in der vom Veranstalter bereitgestellten mobilen Anwendung „Sportworld“ auf berechtigten Geräten zugänglich.`,
                            },
                            {
                                text: `Ziel des Gewinnspiels ist es, durch Vorhersagen möglichst viele Punkte zu sammeln. Teilnehmer können folgende Vorhersagen im Rahmen der Spiele der Fußball-Weltmeisterschaft 2022 treffen: `,
                                child: [
                                    {
                                        text: `Tendenz (Gewinnt die Heimmannschaft, die Auswärtsmannschaft oder geht das Spiel unentschieden aus?)\n
                                        Die Vorhersage in Bezug auf die Tendenz eines Spiels bezieht sich auf das Ergebnis nach Ablauf der regulären Spielzeit. Unter dem Begriff reguläre Spielzeit ist die 90-minütige Spielzeit bzw. die Dauer des Spieles inklusive der von den Schiedsrichtern hinzugefügten Nachspielzeit für Unterbrechungen, aber nicht eine eventuell angesetzte Spielverlängerung oder Elfmeterschießen zu verstehen. Die Vorhersage kann bis zum Beginn des jeweiligen Spiels getroffen und gegebenenfalls geändert werden. Eine nachfolgende Änderung dieser Vorhersage ist nicht möglich.
                                        `,
                                    },
                                    {
                                        text: `Genaues Spielergebnis (Wie viele Tore werden die an einem Spiel teilnehmenden Mannschaften jeweils erzielen und welches exakte Endergebnis ergibt sich daraus?)\n
                                        Die Vorhersage in Bezug auf das genaue Spielergebnis eines Spiels bezieht sich auf das Ergebnis nach Ablauf der regulären Spielzeit. Unter dem Begriff reguläre Spielzeit ist die 90-minütige Spielzeit bzw. die Dauer des Spieles inklusive der von den Schiedsrichtern hinzugefügten Nachspielzeit für Unterbrechungen, aber nicht eine eventuell angesetzte Spielverlängerung oder Elfmeterschießen zu verstehen. Die Vorhersage kann bis zum Beginn des jeweiligen Spiels getroffen und gegebenenfalls geändert werden. Eine nachfolgende Änderung dieser Vorhersage ist nicht möglich.
                                        `,
                                    },
                                    {
                                        text: `Bester Spieler des Spieltages (Most Valuable Player, MVP) (Welcher Spieler ist aus Sicht des Teilnehmers der beste Spieler des Spieltags?)\n
                                        Die Vorhersage in Bezug auf den besten Spieler des Spieltags kann an jedem Tag während des Gewinnspielzeitraumes einmal getroffen werden, an dem mindestens ein Spiel ausgetragen wird (im Folgenden als „Spieltag“ bezeichnet). Zur Auswahl stehen sämtliche bei der FIFA gelisteten Spieler derjenigen Mannschaften, die an dem entsprechenden Spieltag ein Spiel bestreiten. Die Vorhersagen können in der Zeit von 11
                                        :00 Uhr bis 23:59:59 Uhr (MEZ) eines Spieltages getroffen werden. Eine nachfolgende Änderung dieser Vorhersage ist nicht möglich.
                                        `,
                                    },
                                ],
                            },
                            {
                                text: `Jede richtige Vorhersage generiert Punktegewinne für den Teilnehmer, soweit nachfolgend nichts anderes genannt ist, nach folgender Maßgabe:`,
                                child: [
                                    {
                                        text: `Tendenz (Gewinnt die Heimmannschaft, die Auswärtsmannschaft oder geht das Spiel unentschieden aus?)\n
                                        Die Höhe des Punktegewinns fußt in einem Punktesystem. Die bereitgestellten Punkte definieren die Wahrscheinlichkeit für den Eintritt der jeweiligen Vorhersage. Die jeweils angebotenen aktuellen Punkte werden bei der Abgabe der Vorhersage dem Teilnehmer für die spätere Punkteermittlung zugerechnet. Die Punkte werden ständig den spiel- und ergebnisrelevanten Daten, wie z.B. Tabellensituation, letzte Spielergebnisse, Heim- und Auswärtsstärke, aktuelle Performance, Verletzungen von Spielern, angepasst und können daher entsprechenden Änderungen unterliegen. 
                                        `,
                                    },
                                    {
                                        text: `Genaues Spielergebnis (Wie viele Tore werden die an einem Spiel teilnehmenden Mannschaften jeweils erzielen und welches exakte Endergebnis ergibt sich daraus?) \n
                                        Die Höhe des Punktegewinns fußt in einem Punktesystem. Die bereitgestellten Punkte definieren die Wahrscheinlichkeit für den Eintritt der jeweiligen Vorhersage. Die jeweils angebotenen aktuellen Punkte werden bei der Abgabe der Vorhersage dem Teilnehmer für die spätere Punkteermittlung zugerechnet. Die Punkte werden ständig den spiel- und ergebnisrelevanten Daten, wie z.B. Tabellensituation, letzte Spielergebnisse, Heim- und Auswärtsstärke, aktuelle Performance, Verletzungen von Spielern, angepasst und können daher entsprechenden Änderungen unterliegen.
                                        `,
                                    },
                                    {
                                        text: `Bester Spieler des Spieltages (Most Valuable Player, MVP) (Welcher Spieler ist aus Sicht des Teilnehmers der beste Spieler des Spieltags?)\n
                                        Bei der Wahl des Besten Spieler des Spieltages kann der Teilnehmer keine Punkte generieren. Dementsprechend hat diese Vorhersage keinerlei Einfluss auf die generierte(n) Rangliste(n). Die Gewinnerermittlung erfolgt gemäß den unter 5.3 h) festgehaltenen Bestimmungen.
                                        `,
                                    },
                                ],
                            },
                            {
                                text: `Jeder Teilnehmer kann in einer oder mehreren Rangliste(n) unter dem verwendeten Nicknamen die eigene Position und den aktuellen Punktestand einsehen.  `,
                            }
                        ],
                    },
                    {
                        text: `Gewinne\n`,
                        child: [
                            {
                                text: `Die Gewinnerermittlung erfolgt, soweit die Vorhersage auf einem Punktesystem beruht, durch den Veranstalter auf Grundlage der generierten Rangliste der Gewinnspielteilnehmer, sofern bzw. nachdem das jeweilige Ergebnis feststeht. Bei Punktegleichstand wird der Gewinner im Losverfahren ermittelt, d.h. aus den Teilnehmern mit identischer Punkteanzahl wird ein Gewinner per Zufall gezogen.`,
                            },
                            {
                                text: `5.2	Die folgenden Gewinne werden durch die Samsung Electronics GmbH als exklusiven Partner des Gewinnspiels bereitgestellt: `,
                                child: [
                                    {
                                        text: `257 Sportworld Voucher für einen Zeitraum von 6 Monaten`
                                    },
                                    {
                                        text: `1 Samsung Galaxy Tab S8 Ultra 5G (128 GB)`
                                    },
                                    {
                                        text: `1 Samsung Soundbar HW-Q995B/ZG (Atmos)`
                                    },
                                    {
                                        text: `1 Samsung TV GQ65QN900BTXZG (8K)`
                                    },
                                    {
                                        text: `2 Samsung TV GQ65QN800BTXZG (8K) `
                                    },
                                    {
                                        text: `2 Samsung Galaxy Z Flip4 (128 GB) `
                                    },
                                ],
                            },
                            {
                                text: `Innerhalb des Gewinnspiels werden folgende Preise vergeben:  `,
                                child: [
                                    {
                                        text: `Jeden Spieltag gewinnen die besten 10 Teilnehmer jeweils einen Sportworld Voucher für einen Zeitraum von 6 Monaten. Sollten sich aufgrund von Punktgleichheit am Ende eines Spieltages nicht eindeutig 10 Gewinner ermitteln lassen, werden die jeweiligen Gewinnerplätze zwischen den punktgleichen Teilnehmern ausgelost. `
                                    },
                                    {
                                        text: `Der beste Teilnehmer der Gruppenphase erhält ein Samsung Galaxy Tab S8 Ultra 5G (128 GB). Die Gruppenphase umfasst insgesamt 48 Spiele vor Beginn der K.O.-Phase und erstreckt sich voraussichtlich vom 20.11.2022 bis zum 02.12.2022. Sollte sich aufgrund von Punktgleichheit am Ende der Gruppenphase kein alleiniger Gewinner ermitteln lassen, wird der Gewinner zwischen den punktgleichen Teilnehmern ausgelost.`
                                    },
                                    {
                                        text: `Der beste Teilnehmer der Achtelfinal-Phase erhält eine Samsung Soundbar HW-Q995B/ZG (Atmos). Die Achtelfinal-Phase umfasst insgesamt 8 Spiele und erstreckt sich voraussichtlich vom 03.12.2022 bis zum 06.12.2022. Sollte sich aufgrund von Punktgleichheit am Ende der Achtelfinal-Phase kein alleiniger Gewinner ermitteln lassen, wird der Gewinner zwischen den punktgleichen Teilnehmern ausgelost.`
                                    },
                                    {
                                        text: `Der beste Teilnehmer der Viertelfinal-Phase erhält ein Samsung Galaxy Z Flip4 (128 GB). Die Viertelfinal-Phase umfasst insgesamt 4 Spiele und erstreckt sich voraussichtlich vom 09.12.2022 bis zum 10.12.2022. Sollte sich aufgrund von Punktgleichheit am Ende der Viertelfinal-Phase kein alleiniger Gewinner ermitteln lassen, wird der Gewinner zwischen den punktgleichen Teilnehmern ausgelost.`
                                    },
                                    {
                                        text: `Der beste Teilnehmer der Final-Phase erhält einen Samsung TV GQ65QN800BTXZG (8K). Die Final-Phase umfasst insgesamt 4 Spiele (zwei Halbfinal-Spiele, das Spiel um Platz 3 und das Finale) und erstreckt sich voraussichtlich vom 13.12.2022 bis zum 18.12.2022. Sollte sich aufgrund von Punktgleichheit am Ende der Final-Phase kein alleiniger Gewinner ermitteln lassen, wird der Gewinner zwischen den punktgleichen Teilnehmern ausgelost. `
                                    },
                                    {
                                        text: `Am Ende des Turniers erhält der beste Teilnehmer des Gewinnspiels den Hauptgewinn, bestehend aus einem Samsung TV GQ65QN900BTXZG (8K), einem Samsung Galaxy Z Flip4 (128 GB) und einem Sportworld Voucher für einen Zeitraum von 6 Monaten. Der beste Teilnehmer des Gewinnspiels ist als der Teilnehmer definiert, der über den gesamten Zeitraum des Turniers (inklusive aller Spiele und Vorhersagen) die meisten Punkte generiert. Sollte sich aufgrund von Punktgleichheit am Ende des Turniers kein alleiniger Gewinner ermitteln lassen, wird der Gewinner zwischen den punktgleichen Teilnehmern ausgelost. `
                                    },
                                    {
                                        text: `Unter allen Teilnehmern des Gewinnspiels werden nach dem Ende des Turniers 25 Personen ausgelost, die jeweils einen Sportworld Voucher für einen Zeitraum von 6 Monaten erhalten. Als Teilnehmer gelten alle Personen, die mindestens zu einem Spiel der der Fußball-Weltmeisterschaft 2022 eine Vorhersage hinsichtlich der Tendenz und des Spielergebnis getroffen haben. `
                                    },
                                    {
                                        text: `Unter allen Teilnehmern, die mindestens einmal während der Fußball-Weltmeisterschaft 2022 an der Vorhersage zum Spieler des Spieltags (Most Valuable Player, MVP) teilgenommen haben, wird eine Person ausgelost, die einen Samsung TV GQ65QN800BTXZG (8K) sowie ein Sportworld Voucher für einen Zeitraum von 6 Monaten erhält. Die Auslosung findet nach Ende des Turniers statt.`
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        text: `Gewinnerbenachrichtigung und Gewinnvergabe\n`,
                        child: [
                            {
                                text: `Die Gewinner werden unverzüglich nach Ende des jeweiligen Tippspiels und Ermittlung des Gewinners durch Nennung ihres Nicknamens in der mobilen Anwendung „Sportworld“ bekanntgegeben und zusätzlich per E- Mail benachrichtigt. Sollte die angegebene E-Mail-Adresse des Teilnehmers fehlerhaft sein, ist der Veranstalter nicht verpflichtet, die richtigen Daten zu ermitteln. Die Nachteile, die sich aus der Angabe fehlerhafter Kontaktdaten ergeben, gehen zu Lasten des Teilnehmers.`,
                            },
                            {
                                text: `Der Gewinnanspruch verfällt, wenn sich der Gewinner nicht innerhalb von zwei Wochen nach Bekanntgabe des Gewinns beim Veranstalter meldet und ihm seinen Vor- und Familiennamen sowie seine genaue Wohnanschrift mitteilt. Falls der Veranstalter innerhalb dieser Frist keine entsprechende Nachricht erhält, verfällt die Möglichkeit der Annahme des Gewinns und der Veranstalter behält sich vor, einen anderen Teilnehmer zu ermitteln und entsprechend zu benachrichtigen. Der Veranstalter kann verlangen, dass der Gewinner vor der Übergabe des Gewinns seine Identität durch geeignete Dokumente, insbesondere einen gültigen Personalausweis oder Reisepass, nachweist. Der Gewinn wird dem Gewinner nach Wahl des Veranstalters auf dem Postweg übersandt oder persönlich überbracht. `,
                            },
                            {
                                text: `Eine Änderung oder Barauszahlung des Gewinns ist ausgeschlossen.`,
                            },
                            {
                                text: `Bei minderjährigen Teilnehmern erfolgt die Gewinnübergabe an die erziehungsberechtigten Personen.`,
                            },
                            {
                                text: `Die Übergabe des Gewinns kann zurückgestellt werden, wenn dies beispielsweise aus Sicherheitsgründen oder zur Erfüllung gesetzlicher Vorschriften erforderlich ist. Dies ist insbesondere der Fall, wenn a) interne Prozesse zur Sicherstellung der Einhaltung gesetzlicher Vorschriften dies notwendig machen; oder b) der Veranstalter ein berechtigtes Interesse an einer weiteren Sachverhaltsaufklärung hat.`,
                            },
                            {
                                text: `Der Rechtsweg ist im Hinblick auf die Ermittlung des Gewinners ausgeschlossen.`,
                            },
                        ],
                    },
                    {
                        text: `Abstandserklärung\n`,
                        child: [
                            {
                                text: `Das Gewinnspiel steht in keiner Verbindung zu Social Media-Plattformen, wie Facebook und/oder Instagram und wird in keiner Weise von diesen gesponsert, unterstützt oder organisiert und begründet keinerlei Rechtsansprüche gegenüber diesen. Sämtliche Informationen im Rahmen des Gewinnspiels werden ausschließlich vom Veranstalter bereitgestellt. Empfänger der vom Teilnehmer bereitgestellten Informationen ist ausschließlich der Veranstalter. `,
                            },
                            {
                                text: `Fragen, Kommentare oder Beschwerden zum Gewinnspiel sind direkt an den Veranstalter zu richten.`,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    de: {
        general: {
            championship_name: "Samsung Tippspiel"
        },
        tv: {
            leaderboard_daily: "Tagesrangliste des vergangenen Spieltags",
            leaderboard_all_time: "Gesamtrangliste",
            players_of_the_matchday: " Top 10 der Samsung-Community in der Sportworld",
        },
        register: {
            your_detail: "DEINE ANBGABEN:",
            email: "E-mail:",
            nickname: "Spitzname:",
            select_your_land: "WÄHLE DEIN BUNDESLAND?",
            select_land_description: "Wähle dein Land aus, um in der Rangliste angezeigt zu werden",
            select_your_favorite_team: "Wählen Sie Ihr Lieblingsteam",
            terms_and_conditions: "Teilnahmebedingungen:",
            acceptButton: "AKZEPTIEREN",
            error: "Der Spitzname ist bereits in Gebrauch.",
            create_profile: "JETZT TEILNEHMEN"
        },
        matches: {
            pts: "Pkt.",
            all_matches_of_the_day: 'alle Spiele des Tages',
            previous_matches: "vorherige Spiele",
            who_will_win: "Wer gewinnt",
            final_score: "Tipp",
            place_prediction: "Tippen",
            change_prediction: "TIPP ÄNDERN",
            live: "Live",
            group: "Gruppe",
            starts_in: "Startet in",
            days: "Tagen",
            hours: "Stunden  und",
            minutes: "Minuten",
            draw: "REMIS",
            pt: "pkt.",
        },
        votes: {
            best_player_of_previous_matchday: "MVP DES VERGANGENEN SPIELTAGS",
            vote_player_of_the_day: "JETZT MVP VOTEN UND GEWINNCHANCE SICHERN",
            select_country: "Land wählen:",
            select_player: "Spieler wählen:",
            message: "Dankeschön! Deine Stimme wurde abgegeben.",
            back_to_matches: "Zurück zu den Spielen",
        },
        leaderboards: {
            daily: "TAGESÜBERSICHT",
            stage: "TURNIERPHASEN-ÜBERSICHT",
            all_time: "Gesamtübersicht",
            select: "SPIELTAG AUSWÄHLEN",
            group_matches: "Gruppenspiele",
            round_of_16: "Achtelfinale",
            quarter_finals: "Viertelfinale",
            semi_finals: "Halbfinale",
            finals: "Spiel um platz 3 und Finale",
            overall: "allgemein",
            region: "Bundesländer",
            favorite_team: "Lieblingsteam",
            
        },
        prizes: {
            daily: "TAGESPREISE",
            after_stages: "RUNDENPREISE",
            grand_prize: "Hauptpreise",
            daily_data: [
                {
                    image:  dp1_de,
                    name:   '10 x 1 “Samsung Sport Paket” 6 Monate kostenfrei',
                    text:   'Die besten 10 Teilnehmer an jedem Spieltag',
                    value:  'Wert: 20686€',
                },
            ],
            after_stages_data: [
                {
                    image:  sp1_de,
                    name:   '1 x Samsung Galaxy Tab S8 Ultra 5G (128GB)',
                    text:   'Sieger der Rangliste für die Gruppenphase',
                    value:  'Wert: 1179€',
                },
                {
                    image:  sp2_de,
                    name:   '1 x Samsung Soundbar HW-Q995B/ZG',
                    text:   'Sieger der Rangliste für die Achtelfinalphase',
                    value:  'Wert: 1499€',
                },
                {
                    image:  sp3_de,
                    name:   '1 x Samsung Galaxy Z Flip4 (128GB)',
                    text:   'Sieger der Rangliste für die Viertelfinalphase',
                    value:  'Wert: 1099€',
                },
                {
                    image:  sp4_de,
                    name:   '1 x Samsung TV Neo QLED 8K (65")',
                    text:   'Sieger der Rangliste für die Finalspiele',
                    value:  'Wert: 4199€',
                },
            ],
            grand_prize_data: [
                {
                    image:  gp1_de,
                    name:   '1 x Samsung TV 65"NeoQLED + Samsung Galaxy Z Flip4 + „Samsung Sport Paket“ 6 Monate kostenfrei',
                    text:   'Sieger der Gesamtrangliste am Ende des Turniers',
                    value:  'Wert: 6888€',
                },
                {
                    image:  gp2_de,
                    name:   '1 x Samsung TV 65"NeoQLED + „Samsung Sport Paket“ 6 Monate kostenfrei',
                    text:   '1 Gewinner; Verlosung unter allen Teilnehmern, die mindestens einmal am MVP Voting teilgenommen haben',
                    value:  'Wert: 4289€',
                },
                {
                    image:  gp3_de,
                    name:   '25 x 1 „Samsung Sport Paket“ 6 Monate kostenfrei',
                    text:   '25 Gewinner; Verlosung unter allen Teilnehmern, die mindestens einmal einen Tipp im Gewinnspiel abgegeben haben',
                    value:  'Wert: 2248€',
                },
            ],
        },
        rules: {
            what_you_need_to_do: "SO MACHST DU MIT:",
            step: "Schritt",
            info: {
                title: 'SO MACHST DU MIT',
                data: [
                    {
                        text: 'ÖFFNE DIE SPORTWORLD APP AUF DEINEM SAMSUNG DEVICE\n\nDu hast ein Samsung Smartphone oder Tablet: Öffne die mobile Sportworld App und rufe den Menüpunkt „Samsung Tippspiel" auf.\nDu hast ein Samsung Smart TV: Öffne die Sportworld App auf deinem TV und scanne den angezeigten QR-Code im Bereich „Samsung Tippspiel“ mit deinem Smartphone.'
                    },
                    {
                        text: 'REGISTRIERE DICH ODER LOGGE DICH EIN\n\nRegistriere dich kostenfrei auf https://sportworld.tv und erstelle deinen Sportworld-Account. Folge den Anweisungen auf deinem Screen. Du hast bereits einen Account: Dann melde dich unter „Login“ an.'
                    },
                    {
                        text: 'WÄHLE DEINEN NICKNAMEN UND NIMM AM TIPPSPIEL TEIL\n\nWähle in der mobilen Sportworld App den Menüpunkt „Tippspiel“, wähle einen Nicknamen und akzeptiere die Teilnahmebedingungen. Die Teilnahme am Tippspiel ist natürlich kostenlos.'
                    },
                    {
                        text: 'TIPPE AN JEDEM TAG\n\nJeden Tag werden die Partien des Turniertages freigeschaltet. Besuche daher täglich die Sportworld App, um deine Tipps abzugeben. Tippe auf die Tendenz sowie auf das genaue Ergebnis. Bis zum Anstoß kannst du deine Tipps abgeben oder ändern.'
                    },
                    {
                        text: 'PUNKTE MIT ECHTEN WAHRSCHEINLICHKEITEN\n\nMit jedem richtigen Tipp sammelst du Punkte, die auf Basis von echten Eintrittswahrscheinlichkeiten berechnet sind. Miss dich mit der Samsung Community auf unseren Ranglisten.\nFolgende Ranglisten sind dabei für dich entscheidend:',
                        child: [
                            {
                                name: "Allgemein:",
                                text: 'Diese Rangliste wird fortlaufend generiert und umfasst alle Spiele des Turniers.'
                            },
                            {
                                name: "Täglich:",
                                text: 'Diese Rangliste wird für jeden Turniertag generiert und umfasst alle Spiele des jeweiligen Turniertages.'
                            },
                            {
                                name: "Etappen:",
                                text: 'Diese Rangliste wird für jede Turnierphase (Gruppenphase, Achtelfinale, Viertelfinale & Finalspiele) generiert und umfasst alle Spiele der jeweiligen Turnierphase.'
                            },
                        ],
                    },
                    {
                        text: 'GEWINNE ATTRAKTIVE SAMSUNG-PREISE IM WERT VON BIS ZU 40.000 EUR\n\nSchon mit deiner Teilnahme sicherst du dir die Chance auf attraktive Gewinne. Alle Details zu Preisen und der Gewinnspiel-Mechanik findest du unter dem Menüpunkt „Samsung Tippspiel“ in deiner Sportworld App.\n\nDas Besondere: du kannst immer gewinnen, egal wann du einsteigst. Wir wünschen dir viel Spaß und vor allem: viel Erfolg!'
                    },
                ]
            },
        },
        termsAndConditions: [
            {
                title: 'Sportworld',
                type: 'TEILNAHMEBEDINGUNGEN LESEN & AKZEPTIEREN',
                typeRules: 'TEILNAHMEBEDINGUNGEN LESEN',
                data: [
                    {
                        text: `Name des Gewinnspiels und Geltungsbereich\n
                        Der Name des Gewinnspiels lautet SAMSUNG TIPPSPIEL (im Folgenden als „Gewinnspiel“ bezeichnet). Für die Teilnahme an dem Gewinnspiel "SAMSUNG TIPPSPIEL" und dessen Durchführung gelten diese Teilnahmebedingungen. 
                        `,
                    },
                    {
                        text: `Veranstalter des Gewinnspiels und Gewinnspielzeitraum\n`,
                        child: [
                            {
                                text: `Das Gewinnspiel wird von der B1 SmartTV GmbH, Ainmillerstraße 28, 80801 München, eingetragen im Handelsregister des Amtsgerichts München unter HRB 235921 (im Folgenden als „Veranstalter“ bezeichnet) durchgeführt.`,
                            },
                            {
                                text: `Der Gewinnspielzeitraum am Gewinnspiel erstreckt sich auf die Dauer der Fußball-Weltmeisterschaft 2022 vom 20.11.2022, 00:00:01 Uhr (MEZ) bis zum 18.12.2022, 23:59:59 Uhr (MEZ) (der vorgenannte Zeitraum wird nachfolgend auch als „Gewinnspielzeitraum“ bezeichnet). Innerhalb dieses Gewinnspielzeitraums besteht die Möglichkeit, am Gewinnspiel teilzunehmen und Teilnahmeberechtigte können sich bis zum Ende des Gewinnspielzeitraumes zur Teilnahme am Gewinnspiel anmelden.`,
                            }
                        ],
                    },
                    {
                        text: `Teilnahmeberechtigung und Anmeldung zur Teilnahme\n`,
                        child: [
                            {
                                text: `Teilnahmeberechtigt sind natürliche Personen mit einem eingerichteten Nutzeraccount in der mobilen Anwendung „Sportworld“ auf einem berechtigten Gerät (3.4), die ihren Wohnsitz in Deutschland haben. Bei minderjährigen Teilnehmern ist eine Einverständniserklärung eines Erziehungsberechtigten einzureichen.`
                            },
                            {
                                text: `Die Einhaltung der Art und Weise, wie das Gewinnspiel gemäß den Bestimmungen dieser Teilnahmebedingungen durchgeführt wird, ist für alle Teilnehmer verpflichtend.`
                            },
                            {
                                text: `Die Teilnahme am Gewinnspiel ist unentgeltlich und nicht an den Erwerb von Waren oder Dienstleistungen gebunden.`
                            },
                            {
                                text: `Das Gewinnspiel ist nur in der mobilen Anwendung „Sportworld“ auf Smartphones und Tablets des Herstellers Samsung zugänglich sowie auf Smartphones und Tablets anderer Hersteller, wenn Kunden im Rahmen einer Samsung Werbeaktion ein Samsung-Aktionsgerät erworben und hierfür von Samsung einen QR-Code als Zugangsmöglichkeit zum Gewinnspiel auf mobilen Endgeräten erhalten haben sowie für Kunden mit einem SmartTV des Herstellers Samsung ab Modelljahr 2018, auf denen die „Sportworld“ Anwendung installiert ist und ein QR-Code als Zugangsmöglichkeit zum Gewinnspiel auf mobilen Endgeräten bereitgestellt wird.`
                            },
                            {
                                text: `Die Anmeldung zum Gewinnspiel und die Teilnahme am Gewinnspiel erfolgt ausschließlich in der mobilen Anwendung „Sportworld“. In den vorgesehenen Textfeldern des Anmeldeformulars sind eine E-Mail-Adresse und ein selbst gewählter Nickname (= Teilnehmer) einzugeben. Jeder Nickname kann nur einmal vergeben werden und darf keine Umlaute besitzen und maximal 10 Zeichen lang sein. Eine Verwendung von Nicknamen, die gegen Gesetze verstoßen oder beleidigenden oder anstößigen Inhalts sind, sind nicht zugelassen und der Veranstalter behält sich vor, den dagegen verstoßenden Nicknamen zu ändern oder den Teilnehmer vom Gewinnspiel auszuschließen.`
                            },
                            {
                                text: `Jeder Teilnahmeberechtigte darf nur unter einem Nicknamen am Gewinnspiel teilnehmen und kann sich nur einmal registrieren. Mehrfachanmeldungen eines Teilnehmers bleiben zur Wahrung der Chancengleichheit unberücksichtigt. Wenn sich herausstellt, dass ein Teilnehmer mehrere Accounts registriert hat und/oder falsche Identitäten zur Registrierung nutzt, wird dieser vom Gewinnspiel ausgeschlossen.`
                            },
                            {
                                text: `Alle Mitarbeiter des Veranstalters, des Partners Samsung sowie der vom Partner beauftragten Dienstleisters, die an der Gewinnspielkonzeption und -durchführung beteiligt sind, sowie der mit diesen verbundenen Unternehmen, sind von der Teilnahme am Gewinnspiel ausgeschlossen. Auch deren Lebenspartner und Angehörige in häuslicher Gemeinschaft sind von der Teilnahme ausgeschlossen.`
                            },
                            {
                                text: `Ebenfalls ausgeschlossen sind Gewinnspielagenturen sowie automatisierte Gewinnspieldienste.`
                            },
                            {
                                text: `Der Veranstalter behält sich zudem das Recht vor, Personen von der Teilnahme auszuschließen, sofern berechtigte Gründe vorliegen, wie z.B. Verstoß gegen die Teilnahmebedingungen oder unzulässige Beeinflussung oder Manipulation des Gewinnspiels, doppelte oder mehrfache Teilnahme, und gegen diese Personen rechtliche Schritte einzuleiten. Der Veranstalter kann Teilnehmer in diesen Fällen auch nachträglich vom Gewinnspiel ausschließen, Gewinne aberkennen und diese zurückfordern. `
                            },
                            {
                                text: `Der Veranstalter ist berechtigt, das Gewinnspiel jederzeit ohne Vorankündigung und ohne Angabe von Gründen vorzeitig zu beenden, anzupassen oder abzuändern, wenn eine ordnungsgemäße Durchführung des Gewinnspiels ohne entsprechende Maßnahmen aus technischen (z. B. Viren im Computersystem, Manipulation oder Fehler in der Hard- und/oder Software) oder rechtlichen Gründen nicht gewährleistet werden kann. Der Veranstalter ist dazu verpflichtet, die vorzeitige Beendigung bzw. Anpassung unverzüglich bekannt zu geben.`
                            },
                        ],
                    },
                    {
                        text: `Ablauf und Durchführung des Gewinnspiels (Spielregeln)\n`,
                        child: [
                            {
                                text: `Das Gewinnspiel ist in der vom Veranstalter bereitgestellten mobilen Anwendung „Sportworld“ auf berechtigten Geräten zugänglich.`,
                            },
                            {
                                text: `Ziel des Gewinnspiels ist es, durch Vorhersagen möglichst viele Punkte zu sammeln. Teilnehmer können folgende Vorhersagen im Rahmen der Spiele der Fußball-Weltmeisterschaft 2022 treffen: `,
                                child: [
                                    {
                                        text: `Tendenz (Gewinnt die Heimmannschaft, die Auswärtsmannschaft oder geht das Spiel unentschieden aus?)\n
                                        Die Vorhersage in Bezug auf die Tendenz eines Spiels bezieht sich auf das Ergebnis nach Ablauf der regulären Spielzeit. Unter dem Begriff reguläre Spielzeit ist die 90-minütige Spielzeit bzw. die Dauer des Spieles inklusive der von den Schiedsrichtern hinzugefügten Nachspielzeit für Unterbrechungen, aber nicht eine eventuell angesetzte Spielverlängerung oder Elfmeterschießen zu verstehen. Die Vorhersage kann bis zum Beginn des jeweiligen Spiels getroffen und gegebenenfalls geändert werden. Eine nachfolgende Änderung dieser Vorhersage ist nicht möglich.
                                        `,
                                    },
                                    {
                                        text: `Genaues Spielergebnis (Wie viele Tore werden die an einem Spiel teilnehmenden Mannschaften jeweils erzielen und welches exakte Endergebnis ergibt sich daraus?)\n
                                        Die Vorhersage in Bezug auf das genaue Spielergebnis eines Spiels bezieht sich auf das Ergebnis nach Ablauf der regulären Spielzeit. Unter dem Begriff reguläre Spielzeit ist die 90-minütige Spielzeit bzw. die Dauer des Spieles inklusive der von den Schiedsrichtern hinzugefügten Nachspielzeit für Unterbrechungen, aber nicht eine eventuell angesetzte Spielverlängerung oder Elfmeterschießen zu verstehen. Die Vorhersage kann bis zum Beginn des jeweiligen Spiels getroffen und gegebenenfalls geändert werden. Eine nachfolgende Änderung dieser Vorhersage ist nicht möglich.
                                        `,
                                    },
                                    {
                                        text: `Bester Spieler des Spieltages (Most Valuable Player, MVP) (Welcher Spieler ist aus Sicht des Teilnehmers der beste Spieler des Spieltags?)\n
                                        Die Vorhersage in Bezug auf den besten Spieler des Spieltags kann an jedem Tag während des Gewinnspielzeitraumes einmal getroffen werden, an dem mindestens ein Spiel ausgetragen wird (im Folgenden als „Spieltag“ bezeichnet). Zur Auswahl stehen sämtliche bei der FIFA gelisteten Spieler derjenigen Mannschaften, die an dem entsprechenden Spieltag ein Spiel bestreiten. Die Vorhersagen können in der Zeit von 11:00 Uhr bis 23:59:59 Uhr (MEZ) eines Spieltages getroffen werden. Eine nachfolgende Änderung dieser Vorhersage ist nicht möglich.
                                        `,
                                    },
                                ],
                            },
                            {
                                text: `Jede richtige Vorhersage generiert Punktegewinne für den Teilnehmer, soweit nachfolgend nichts anderes genannt ist, nach folgender Maßgabe:`,
                                child: [
                                    {
                                        text: `Tendenz (Gewinnt die Heimmannschaft, die Auswärtsmannschaft oder geht das Spiel unentschieden aus?)\n
                                        Die Höhe des Punktegewinns fußt in einem Punktesystem. Die bereitgestellten Punkte definieren die Wahrscheinlichkeit für den Eintritt der jeweiligen Vorhersage. Die jeweils angebotenen aktuellen Punkte werden bei der Abgabe der Vorhersage dem Teilnehmer für die spätere Punkteermittlung zugerechnet. Die Punkte werden ständig den spiel- und ergebnisrelevanten Daten, wie z.B. Tabellensituation, letzte Spielergebnisse, Heim- und Auswärtsstärke, aktuelle Performance, Verletzungen von Spielern, angepasst und können daher entsprechenden Änderungen unterliegen. 
                                        `,
                                    },
                                    {
                                        text: `Genaues Spielergebnis (Wie viele Tore werden die an einem Spiel teilnehmenden Mannschaften jeweils erzielen und welches exakte Endergebnis ergibt sich daraus?) \n
                                        Die Höhe des Punktegewinns fußt in einem Punktesystem. Die bereitgestellten Punkte definieren die Wahrscheinlichkeit für den Eintritt der jeweiligen Vorhersage. Die jeweils angebotenen aktuellen Punkte werden bei der Abgabe der Vorhersage dem Teilnehmer für die spätere Punkteermittlung zugerechnet. Die Punkte werden ständig den spiel- und ergebnisrelevanten Daten, wie z.B. Tabellensituation, letzte Spielergebnisse, Heim- und Auswärtsstärke, aktuelle Performance, Verletzungen von Spielern, angepasst und können daher entsprechenden Änderungen unterliegen.
                                        `,
                                    },
                                    {
                                        text: `Bester Spieler des Spieltages (Most Valuable Player, MVP) (Welcher Spieler ist aus Sicht des Teilnehmers der beste Spieler des Spieltags?)\n
                                        Bei der Wahl des Besten Spieler des Spieltages kann der Teilnehmer keine Punkte generieren. Dementsprechend hat diese Vorhersage keinerlei Einfluss auf die generierte(n) Rangliste(n). Die Gewinnerermittlung erfolgt gemäß den unter 5.3 h) festgehaltenen Bestimmungen.
                                        `,
                                    },
                                ],
                            },
                            {
                                text: `Jeder Teilnehmer kann in einer oder mehreren Rangliste(n) unter dem verwendeten Nicknamen die eigene Position und den aktuellen Punktestand einsehen.  `,
                            }
                        ],
                    },
                    {
                        text: `Gewinne\n`,
                        child: [
                            {
                                text: `Die Gewinnerermittlung erfolgt, soweit die Vorhersage auf einem Punktesystem beruht, durch den Veranstalter auf Grundlage der generierten Rangliste der Gewinnspielteilnehmer, sofern bzw. nachdem das jeweilige Ergebnis feststeht. Bei Punktegleichstand wird der Gewinner im Losverfahren ermittelt, d.h. aus den Teilnehmern mit identischer Punkteanzahl wird ein Gewinner per Zufall gezogen.`,
                            },
                            {
                                text: `5.2	Die folgenden Gewinne werden durch die Samsung Electronics GmbH als exklusiven Partner des Gewinnspiels bereitgestellt: `,
                                child: [
                                    {
                                        text: `257 Sportworld Voucher für einen Zeitraum von 6 Monaten`
                                    },
                                    {
                                        text: `1 Samsung Galaxy Tab S8 Ultra 5G (128 GB)`
                                    },
                                    {
                                        text: `1 Samsung Soundbar HW-Q995B/ZG (Atmos)`
                                    },
                                    {
                                        text: `1 Samsung TV GQ65QN900BTXZG (8K)`
                                    },
                                    {
                                        text: `2 Samsung TV GQ65QN800BTXZG (8K) `
                                    },
                                    {
                                        text: `2 Samsung Galaxy Z Flip4 (128 GB) `
                                    },
                                ],
                            },
                            {
                                text: `Innerhalb des Gewinnspiels werden folgende Preise vergeben:  `,
                                child: [
                                    {
                                        text: `Jeden Spieltag gewinnen die besten 10 Teilnehmer jeweils einen Sportworld Voucher für einen Zeitraum von 6 Monaten. Sollten sich aufgrund von Punktgleichheit am Ende eines Spieltages nicht eindeutig 10 Gewinner ermitteln lassen, werden die jeweiligen Gewinnerplätze zwischen den punktgleichen Teilnehmern ausgelost. `
                                    },
                                    {
                                        text: `Der beste Teilnehmer der Gruppenphase erhält ein Samsung Galaxy Tab S8 Ultra 5G (128 GB). Die Gruppenphase umfasst insgesamt 48 Spiele vor Beginn der K.O.-Phase und erstreckt sich voraussichtlich vom 20.11.2022 bis zum 02.12.2022. Sollte sich aufgrund von Punktgleichheit am Ende der Gruppenphase kein alleiniger Gewinner ermitteln lassen, wird der Gewinner zwischen den punktgleichen Teilnehmern ausgelost.`
                                    },
                                    {
                                        text: `Der beste Teilnehmer der Achtelfinal-Phase erhält eine Samsung Soundbar HW-Q995B/ZG (Atmos). Die Achtelfinal-Phase umfasst insgesamt 8 Spiele und erstreckt sich voraussichtlich vom 03.12.2022 bis zum 06.12.2022. Sollte sich aufgrund von Punktgleichheit am Ende der Achtelfinal-Phase kein alleiniger Gewinner ermitteln lassen, wird der Gewinner zwischen den punktgleichen Teilnehmern ausgelost.`
                                    },
                                    {
                                        text: `Der beste Teilnehmer der Viertelfinal-Phase erhält ein Samsung Galaxy Z Flip4 (128 GB). Die Viertelfinal-Phase umfasst insgesamt 4 Spiele und erstreckt sich voraussichtlich vom 09.12.2022 bis zum 10.12.2022. Sollte sich aufgrund von Punktgleichheit am Ende der Viertelfinal-Phase kein alleiniger Gewinner ermitteln lassen, wird der Gewinner zwischen den punktgleichen Teilnehmern ausgelost.`
                                    },
                                    {
                                        text: `Der beste Teilnehmer der Final-Phase erhält einen Samsung TV GQ65QN800BTXZG (8K). Die Final-Phase umfasst insgesamt 4 Spiele (zwei Halbfinal-Spiele, das Spiel um Platz 3 und das Finale) und erstreckt sich voraussichtlich vom 13.12.2022 bis zum 18.12.2022. Sollte sich aufgrund von Punktgleichheit am Ende der Final-Phase kein alleiniger Gewinner ermitteln lassen, wird der Gewinner zwischen den punktgleichen Teilnehmern ausgelost. `
                                    },
                                    {
                                        text: `Am Ende des Turniers erhält der beste Teilnehmer des Gewinnspiels den Hauptgewinn, bestehend aus einem Samsung TV GQ65QN900BTXZG (8K), einem Samsung Galaxy Z Flip4 (128 GB) und einem Sportworld Voucher für einen Zeitraum von 6 Monaten. Der beste Teilnehmer des Gewinnspiels ist als der Teilnehmer definiert, der über den gesamten Zeitraum des Turniers (inklusive aller Spiele und Vorhersagen) die meisten Punkte generiert. Sollte sich aufgrund von Punktgleichheit am Ende des Turniers kein alleiniger Gewinner ermitteln lassen, wird der Gewinner zwischen den punktgleichen Teilnehmern ausgelost. `
                                    },
                                    {
                                        text: `Unter allen Teilnehmern des Gewinnspiels werden nach dem Ende des Turniers 25 Personen ausgelost, die jeweils einen Sportworld Voucher für einen Zeitraum von 6 Monaten erhalten. Als Teilnehmer gelten alle Personen, die mindestens zu einem Spiel der der Fußball-Weltmeisterschaft 2022 eine Vorhersage hinsichtlich der Tendenz und des Spielergebnis getroffen haben. `
                                    },
                                    {
                                        text: `Unter allen Teilnehmern, die mindestens einmal während der Fußball-Weltmeisterschaft 2022 an der Vorhersage zum Spieler des Spieltags (Most Valuable Player, MVP) teilgenommen haben, wird eine Person ausgelost, die einen Samsung TV GQ65QN800BTXZG (8K) sowie ein Sportworld Voucher für einen Zeitraum von 6 Monaten erhält. Die Auslosung findet nach Ende des Turniers statt.`
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        text: `Gewinnerbenachrichtigung und Gewinnvergabe\n`,
                        child: [
                            {
                                text: `Die Gewinner werden unverzüglich nach Ende des jeweiligen Tippspiels und Ermittlung des Gewinners durch Nennung ihres Nicknamens in der mobilen Anwendung „Sportworld“ bekanntgegeben und zusätzlich per E- Mail benachrichtigt. Sollte die angegebene E-Mail-Adresse des Teilnehmers fehlerhaft sein, ist der Veranstalter nicht verpflichtet, die richtigen Daten zu ermitteln. Die Nachteile, die sich aus der Angabe fehlerhafter Kontaktdaten ergeben, gehen zu Lasten des Teilnehmers.`,
                            },
                            {
                                text: `Der Gewinnanspruch verfällt, wenn sich der Gewinner nicht innerhalb von zwei Wochen nach Bekanntgabe des Gewinns beim Veranstalter meldet und ihm seinen Vor- und Familiennamen sowie seine genaue Wohnanschrift mitteilt. Falls der Veranstalter innerhalb dieser Frist keine entsprechende Nachricht erhält, verfällt die Möglichkeit der Annahme des Gewinns und der Veranstalter behält sich vor, einen anderen Teilnehmer zu ermitteln und entsprechend zu benachrichtigen. Der Veranstalter kann verlangen, dass der Gewinner vor der Übergabe des Gewinns seine Identität durch geeignete Dokumente, insbesondere einen gültigen Personalausweis oder Reisepass, nachweist. Der Gewinn wird dem Gewinner nach Wahl des Veranstalters auf dem Postweg übersandt oder persönlich überbracht. `,
                            },
                            {
                                text: `Eine Änderung oder Barauszahlung des Gewinns ist ausgeschlossen.`,
                            },
                            {
                                text: `Bei minderjährigen Teilnehmern erfolgt die Gewinnübergabe an die erziehungsberechtigten Personen.`,
                            },
                            {
                                text: `Die Übergabe des Gewinns kann zurückgestellt werden, wenn dies beispielsweise aus Sicherheitsgründen oder zur Erfüllung gesetzlicher Vorschriften erforderlich ist. Dies ist insbesondere der Fall, wenn a) interne Prozesse zur Sicherstellung der Einhaltung gesetzlicher Vorschriften dies notwendig machen; oder b) der Veranstalter ein berechtigtes Interesse an einer weiteren Sachverhaltsaufklärung hat.`,
                            },
                            {
                                text: `Der Rechtsweg ist im Hinblick auf die Ermittlung des Gewinners ausgeschlossen.`,
                            },
                        ],
                    },
                    {
                        text: `Abstandserklärung\n`,
                        child: [
                            {
                                text: `Das Gewinnspiel steht in keiner Verbindung zu Social Media-Plattformen, wie Facebook und/oder Instagram und wird in keiner Weise von diesen gesponsert, unterstützt oder organisiert und begründet keinerlei Rechtsansprüche gegenüber diesen. Sämtliche Informationen im Rahmen des Gewinnspiels werden ausschließlich vom Veranstalter bereitgestellt. Empfänger der vom Teilnehmer bereitgestellten Informationen ist ausschließlich der Veranstalter. `,
                            },
                            {
                                text: `Fragen, Kommentare oder Beschwerden zum Gewinnspiel sind direkt an den Veranstalter zu richten.`,
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
export default translateModel;