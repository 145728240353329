import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import './Loading.scss';
  
const Loading = () => { 
    return (
        <div className="loading">
            <ClipLoader
                color={"white"}
                sizeunit={"px"}
                size={60}
                loading={true}
            />
        </div>
    );
};
  
export default Loading;