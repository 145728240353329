import React, { useRef, useState } from "react";

import './Select.scss';

import downIcon from '../../assets/icons/down-icon.svg';
import { useStoreState } from "easy-peasy";

const Select = ({label, options, setValue, last}) => {
    const { lang } = useStoreState( (store) => store.user );
    const dropdownMenuRef = useRef(null); 
    
    const [ isExtended, setIsExtended ] = useState(false);
    // const [ selectedOption, setSelectedOption ] = useState(label ? null : { value: options[0].value, label: options[0].label });
    const [ selectedOption, setSelectedOption ] = useState(
        label ? 
            null 
            : 
            last == true ? 
                lang == 'de' && !!options[options.length - 1].labelDE ? 
                    {   
                        value: options[options.length - 1].value, 
                        label: !!options[options.length - 1].day ? 
                            `${options[options.length - 1].labelDE} - ${options[options.length - 1].day}.${options[options.length - 1].month + 1}` 
                            : 
                            options[options.length - 1].labelDE
                    } 
                    : 
                    {   
                        value: options[options.length - 1].value, 
                        label: !!options[options.length - 1].day ?
                            `${options[options.length - 1].label} - ${options[options.length - 1].day}.${options[options.length - 1].month + 1}` 
                            : 
                            options[options.length - 1].label
                    } 
                : 
                lang == 'de' && !!options[0].labelDE ? 
                    {   
                        value: options[0].value, 
                        label: !!options[0].day ? 
                            `${options[0].labelDE} - ${options[0].day}.${options[0].month + 1}` 
                            : 
                            options[0].labelDE
                    } 
                    : 
                    {   
                        value: options[0].value, 
                        label: !!options[0].day ?
                            `${options[0].label} - ${options[0].day}.${options[0].month + 1}` 
                            : 
                            options[0].label
                    } 
    );

    const extendSelect = () => {
        setIsExtended(!isExtended);
    }
    const handleSelectedOption = (value, label) => {
        setSelectedOption({ value: value, label: label });
        setValue(value);
        setIsExtended(false);
        dropdownMenuRef.current.scrollTop = 0;
    }



    return (
        <div className={`select-container${isExtended ? ' extended':''}`}> 
            <div className="dropdown" onClick={extendSelect}>
                <span>{label && selectedOption == null ? label : selectedOption.label}</span>
                <span className="icon">
                    <img src={downIcon} alt=">" />
                </span>
            </div> 
            <ul className="dropdown-menu" ref={dropdownMenuRef}>
                {
                    options.map(
                        (option, i) => 
                            option.value != selectedOption?.value ?
                            <li key={i} 
                                id={option.value} 
                                onClick={() => { 
                                    handleSelectedOption(
                                        option.value, 
                                        !!option.day ? 
                                            lang == 'de' && option.labelDE ? 
                                                `${option.labelDE} - ${option.day}.${option.month + 1}` 
                                                : 
                                                `${option.label} - ${option.day}.${option.month + 1}` 
                                            : 
                                            lang == 'de' && option.labelDE ? 
                                                option.labelDE : 
                                                option.label
                                    ) 
                                }
                                }
                            >
                                <span className="day">{ lang == 'de' && option.labelDE ? option.labelDE : option.label}</span>
                                <span className="date">{option.date}</span>
                            </li>:null
                    )
                }
            </ul> 
        </div> 
    );
};
export default Select;